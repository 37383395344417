import { IItemProps } from '@ui';
import React, { memo, useCallback } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import * as yup from 'yup';

import { Status } from '@constants';
import { BottomActions, CheckBoxError } from 'components';
import { Footer } from 'containers/Footer';
import { useResolverForm, useCUDChargeCodeUnit } from 'hooks';
import trans from 'translation';
import { KGrid, KInput, KListItem, KForm } from 'uikit';
import { ValidationUtils } from 'utils';

import { useDeleteChargeCodeUnitsHelper } from '../../helpers';

interface IFormData {
  id?: number;
  code: string;
  name: string;
  displayPosition?: number;
  isAir: boolean;
  isOcean: boolean;
  status: Status;
  isEdit?: boolean;
  vnName: string;
}

const schema = yup.object().shape({
  code: ValidationUtils.required(),
  name: ValidationUtils.required(),
  displayPosition: ValidationUtils.requiredDisplayPosition(),
  isAir: ValidationUtils.requiredCheckBoxAirOcean()
});

const Form = ({ item }: IItemProps) => {
  const {
    createMutation: { mutate: createMutation },
    updateMutation: { mutate: updateMutation },
    modifyLoading
  } = useCUDChargeCodeUnit();

  const { onDelete, deleteLoading } = useDeleteChargeCodeUnitsHelper();

  const isEdit = !!item;

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        id: item?.id,
        code: item?.code ?? '',
        name: item?.name ?? '',
        displayPosition: item?.displayPosition ?? 0,
        isAir: item?.isAir ?? true,
        isOcean: item?.isOcean ?? true,
        status: item?.status ?? Status.Active,
        vnName: item?.vnName ?? ''
      }
    }
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      const mParams = {
        ...data,
        displayPosition: data.displayPosition || undefined
      };

      if (!!mParams.id) {
        updateMutation(mParams);
      } else {
        createMutation(mParams);
      }
    },
    [createMutation, updateMutation]
  );

  return (
    <FormProvider {...methods}>
      <KForm onSubmit={methods.handleSubmit(onFormValid)}>
        <KGrid.Container>
          <KGrid.Item xs={2}>
            <Controller
              name="code"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.TextField
                    {...field}
                    required
                    label={trans('code')}
                    message={error?.message}
                    disabled={item?.isSystem}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={2}>
            <Controller
              name="name"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.TextField
                    {...field}
                    required
                    label={trans('name')}
                    message={error?.message}
                    disabled={item?.isSystem}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={2}>
            <Controller
              name="displayPosition"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.TextField
                    {...field}
                    required
                    type="number"
                    label={trans('display_order')}
                    message={error?.message}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={2}>
            <Controller
              name="vnName"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('translated_name')}
                    disabled={item?.isSystem}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={2} textAlign={'center'}>
            <Controller
              name="isOcean"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KListItem.Checkbox
                    {...field}
                    label={trans('ocean')}
                    checked={field.value}
                    disabled={item?.isSystem}
                  />
                );
              }}
            />
            <Controller
              name="isAir"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <>
                    <KListItem.Checkbox
                      {...field}
                      label={trans('air')}
                      checked={field.value}
                      disabled={item?.isSystem}
                    />
                    <CheckBoxError errMessage={error?.message} />
                  </>
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={2}>
            <KListItem.Radio
              label={trans('is_system')}
              checked={item?.isSystem}
              disabled
              marginT="0.75rem"
            />
          </KGrid.Item>

          <Footer.Library item={item} />
        </KGrid.Container>

        <BottomActions
          showDelete={isEdit}
          btnProps={[
            {
              onPress: () => onDelete?.([item]),
              isLoading: deleteLoading,
              disabled: item?.isSystem
            },
            {
              isLoading: modifyLoading
            }
          ]}
        />
      </KForm>
    </FormProvider>
  );
};

export default memo(Form);
