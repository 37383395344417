import {
  ArApType,
  ContainerStatus,
  InvoiceCategory,
  ServiceType,
  generateOptions
} from '@constants';
import { useAlertMutationEnhancer, useCUDService, useCUDWip } from 'hooks';

export const useDeleteService = () => {
  const { deleteMutation: mutation } = useCUDService();

  return useAlertMutationEnhancer({ mutation });
};

export const checkComplete = (containerStatus: ContainerStatus) => {
  return containerStatus === ContainerStatus.Complete;
};

export const useDeleteWip = (params: {
  category: InvoiceCategory;
  params: {
    screen: ArApType;
    serviceType?: ServiceType;
  };
}) => {
  const { deleteMutation: mutation } = useCUDWip(params);

  return useAlertMutationEnhancer({ mutation });
};

export enum LocalAllStatus {
  Draft = 'DRF',
  Active = 'ACT',
  Complete = 'COM'
}

export enum LocalCompleteStatus {
  Active = 'ACT',
  Complete = 'COM'
}

export enum LocalDraftStatus {
  Draft = 'DRF',
  Active = 'ACT'
}

enum LocalAllStatus1 {
  PEN = 'PEN',
  PRO = 'PRO',
  COM = 'COM'
}

enum LocalPendingStatus1 {
  PEN = 'PEN',
  PRO = 'PRO'
}

enum LocalCompleteStatus1 {
  PRO = 'PRO',
  COM = 'COM'
}

export enum HandlingStatus {
  PEN = 'PEN',
  COM = 'COM'
}

export const optionStatus = (status: LocalAllStatus1) => {
  if (status === LocalAllStatus1.COM) {
    return generateOptions(LocalCompleteStatus1);
  }

  if (status === LocalAllStatus1.PEN) {
    return generateOptions(LocalPendingStatus1);
  }

  return generateOptions(LocalAllStatus1);
};
