import { IUseAgTableResult } from '@hooks-dto';
import Box from '@mui/material/Box';
import {
  CsvExportParams,
  RowDataUpdatedEvent,
  SelectionChangedEvent,
  Column
} from 'ag-grid-community';
import { AgGridReactProps } from 'ag-grid-react';
import { difference } from 'lodash';
import React, { useCallback, useLayoutEffect, useState } from 'react';

import { TableName } from '@constants';
import AgTable from 'containers/AgTable';
import useAgTable from 'hooks/core/useAgTable';
import trans from 'translation';
import { KButton, KColors } from 'uikit';
import { typedMemo } from 'utils/common';

type SheetDataProps<T> = {
  data: T[];
  tableName: TableName;
  disableBodyViewport?: boolean;
  /** onAdd exitst, addLastRow will not be call */
  onAdd?: ({
    onAddLastRow
  }: {
    onAddLastRow?: ({ params }: { params: T[] }) => void;
  }) => void;
  onAddParams?: {
    /** true hidden, default false show */
    hidden?: boolean;
    initialValue?: Partial<T>;
    disabled?: boolean;
  };

  onDeleteParams?: {
    /** true hidden, default false show */
    hidden?: boolean;
  };
  onFromTemplateParams?: {
    disabled?: boolean;
  };
  /** onDelete exitst, removeLastRow will not be call */
  onDelete?: (data: T[], onSuccess?: () => void) => void;
  refetch?: () => void;
  getAllRowData?: (data: T[]) => void;
  onFromTemplate?: (
    onAddLastRow: (params: { params: T[]; remove?: T[] }) => void
  ) => void;
  /**remove: object[] have to id */
  onFromWip?: (
    onAddLastRow: (params: { params: T[]; remove?: T[] }) => void
  ) => void;
  onFromWipParams?: {
    label?: string;
    disabled?: boolean;
  };
  table?: IUseAgTableResult<any>;
} & AgGridReactProps<T>;

const SheetData = <T,>({
  data = [],
  columnDefs,
  tableName,
  onFromTemplateParams,
  onFromWipParams = { label: 'from_wip' },
  onAddParams,
  onDeleteParams,
  disableBodyViewport,
  table: _table,

  onAdd,
  onDelete,
  refetch,
  getAllRowData,
  onFromTemplate,
  onFromWip,
  ...rest
}: SheetDataProps<T>) => {
  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState<T[]>([]);
  const [totalSelectRow, setTotalSelectRow] = useState<T[]>([]);
  const onSelectionChanged = useCallback((event: SelectionChangedEvent<T>) => {
    setTotalSelectRow(event.api.getSelectedRows());
  }, []);

  const table = !_table
    ? useAgTable({
        name: tableName,
        data,
        colDefs: columnDefs ?? [],
        onAddParams
      })
    : _table;

  const onBtnExport = useCallback(() => {
    const excludeColumn = ['checkbox', 'action'];
    const columns = table.gridRef.current?.api.getColumns() ?? [];
    const colIdsColumn: any[] = columns.map((col: Column) => col.getColId());
    const params: CsvExportParams = {
      fileName: tableName,
      columnKeys: difference(colIdsColumn, excludeColumn)
    };

    table.gridRef.current?.api.exportDataAsCsv(params);
  }, [table.gridRef, tableName]);

  const getAllRows = useCallback(
    (event: RowDataUpdatedEvent<T>) => {
      const rows: T[] = [];
      event.api.forEachNodeAfterFilter(rowNode => rows.push(rowNode.data as T));
      if (rowData.length === 0 && rowData.length === 0) {
        getAllRowData?.(rows);
        return;
      }
      if (JSON.stringify(rowData) != JSON.stringify(rows)) {
        getAllRowData?.(rows);
        return;
      }
    },
    [getAllRowData, rowData]
  );

  const onAddLastRow = useCallback(
    ({ params, remove = [] }: { params?: T[]; remove?: T[] }) => {
      table.gridRef.current?.api.applyTransaction({
        add:
          params ??
          ([{ action: 'addLastRow', ...onAddParams?.initialValue }] as T[])
      });
      const _remove = remove.map(i => {
        return rowData.find(j => {
          // @ts-ignore: Unreachable code error
          return j.id === i.id;
        });
      });
      table.gridRef.current?.api.applyTransaction({
        remove: _remove as T[]
      });
    },
    [onAddParams?.initialValue, rowData, table.gridRef]
  );

  const onPressPlusIcon = useCallback(() => {
    if (onAdd) {
      onAdd({ onAddLastRow });
    } else {
      onAddLastRow({});
    }
  }, [onAdd, onAddLastRow]);

  const onPressDelete = useCallback(() => {
    if (onDelete) {
      onDelete?.(totalSelectRow);
    } else {
      table.gridRef.current?.api.applyTransaction({
        remove: totalSelectRow
      });
    }
  }, [onDelete, table.gridRef, totalSelectRow]);

  useLayoutEffect(() => {
    setRowData([...data]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)]);

  return (
    <Box
      // wrapping container with theme & size */
      style={{ width: '100%' }} // the grid will fill the size of the parent container
    >
      <AgTable<T>
        {...table}
        {...(!onAddParams?.hidden && {
          onAdd: onPressPlusIcon
        })}
        disableBodyViewport={disableBodyViewport}
        // rightNode={
        //   <Tooltip
        //     title="Export excel"
        //     slotProps={{
        //       popper: {
        //         modifiers: [
        //           {
        //             name: 'offset',
        //             options: {
        //               offset: [0, -10]
        //             }
        //           }
        //         ]
        //       }
        //     }}
        //   >
        //     <KButton.Icon
        //       key={'download-excel'}
        //       icon="CloudDownloadOutlined"
        //       onPress={onBtnExport}
        //     />
        //   </Tooltip>
        // }
        leftNode={
          <Box>
            <div hidden={onDeleteParams?.hidden}>
              {totalSelectRow.length > 0 && !disableBodyViewport && (
                <KButton.Icon
                  key={'delete-item'}
                  icon="Delete"
                  onPress={onPressDelete}
                  marginR="0.5rem"
                  br="x"
                  brC={KColors.primary.normal}
                  brW={1}
                  negativePadding="0.25rem"
                />
              )}
            </div>
            {onFromWip && (
              <KButton.Outline
                marginR="0.5rem"
                icon="List"
                iconColor="#208B3A"
                textColor="#208B3A"
                background="#B7EFC5"
                title={trans(onFromWipParams.label)}
                disabled={onFromWipParams.disabled}
                onPress={() => {
                  onFromWip(onAddLastRow);
                }}
              />
            )}
            {onFromTemplate && (
              <KButton.Outline
                disabled={onFromTemplateParams?.disabled}
                marginR="0.5rem"
                icon="DashboardCustomize"
                iconColor={KColors.palette.blue.w700}
                textColor={KColors.palette.blue.w700}
                background={KColors.palette.blue.w100}
                title={trans('from_template')}
                onPress={() => {
                  onFromTemplate(onAddLastRow);
                }}
              />
            )}
          </Box>
        }
        onRefresh={refetch}
        rowData={rowData}
        singleClickEdit
        onSelectionChanged={onSelectionChanged}
        onCellValueChanged={event => {
          const rows: T[] = [];
          event.api.forEachNodeAfterFilter(rowNode =>
            rows.push(rowNode.data as T)
          );
          getAllRowData?.(rows);
        }}
        onRowDataUpdated={event => {
          getAllRows(event);
        }}
        onRowEditingStopped={event => {
          getAllRows(event);
        }}
        {...rest}
      />
    </Box>
  );
};

export default typedMemo(SheetData);
