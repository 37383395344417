import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgTableProps } from '@ui';
import { ColDef, SuppressKeyboardEventParams } from 'ag-grid-community';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import clsx from 'clsx';
import React, { useCallback, useMemo, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { KContainer, KLoading } from 'uikit';

import './index.css';
import { useColTypes } from './helpers';
import LeftActions from './LeftActions';
import RightActions from './RightActions';
import { useStyles } from './styles';
import Tooltip from './Tooltip';

const AgTable = <T extends any>(props: AgTableProps<T>) => {
  const {
    gridRef,

    onAdd,
    onSearch,
    onRefresh,
    onDelete,
    onExport,

    onAddParams,
    onDeleteParams,
    onExportParams,

    leftNode,
    rightNode,

    showActions,
    showLeftActions,
    showRightActions,

    showSearch,
    keyword,

    showViewColumns = true,

    rowData,
    isLoading,
    disableBodyViewport,

    hasSelectedData,

    name,
    height,
    sortable,

    ...rest
  } = props;
  const classes = useStyles();

  const onSKE = useCallback(
    ({ event }: SuppressKeyboardEventParams<T>) => {
      if (event.key === 'Tab') {
        gridRef.current?.api.stopEditing();
      }
      return false;
    },
    [gridRef]
  );

  const defaultColDef = useRef<ColDef<T, any>>({
    filter: false,
    autoHeight: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    wrapText: true,
    initialWidth: 130,
    minWidth: 130,
    sortable: sortable ?? true,
    suppressKeyboardEvent: onSKE,
    tooltipComponent: Tooltip
  });

  const uuid = useMemo(() => uuidv4(), []);

  const colTypes = useColTypes<T>();

  const len = (rowData ?? []).length;
  const hasRowData = !!rowData;

  const options: AgGridReactProps<T> = useMemo(() => {
    return {
      pagination: true,
      paginationPageSize: 20,
      paginationPageSizeSelector: [20, 50, 100],

      suppressRowClickSelection: true,
      rowDragManaged: true,
      suppressMoveWhenRowDragging: true,
      reactiveCustomComponents: true,
      // singleClickEdit: true,
      stopEditingWhenCellsLoseFocus: true,
      enableCellTextSelection: true,
      suppressMultiSort: true,
      tooltipShowDelay: 500,
      tooltipInteraction: true,
      rowSelection: 'multiple',

      animateRows: hasRowData && len <= 20,

      autoSizeStrategy: {
        type: 'fitGridWidth'
      },

      ...rest
    };
  }, [hasRowData, len, rest]);

  const _showLeftActions = useMemo(() => {
    return (
      !onAddParams?.hidden ||
      showLeftActions ||
      showSearch ||
      !!leftNode ||
      (!!hasSelectedData && onDelete)
    );
  }, [
    hasSelectedData,
    leftNode,
    onAddParams?.hidden,
    onDelete,
    showLeftActions,
    showSearch
  ]);

  const _showRightActions = useMemo(() => {
    return (
      (!onExportParams?.hidden && onExport) ||
      showRightActions ||
      showViewColumns ||
      !!rightNode ||
      !!onRefresh
    );
  }, [
    onExport,
    onExportParams?.hidden,
    onRefresh,
    rightNode,
    showRightActions,
    showViewColumns
  ]);

  const _showActions = useMemo(() => {
    return showActions || _showLeftActions || _showRightActions;
  }, [_showLeftActions, _showRightActions, showActions]);

  return (
    <>
      {_showActions && (
        <KContainer.View
          row
          alignItems
          justifyContent="space-between"
          marginB="0.75rem"
        >
          {_showLeftActions ? (
            <LeftActions
              id={uuid}
              leftNode={leftNode}
              showSearch={showSearch}
              keyword={keyword}
              onAdd={onAdd}
              onAddParams={onAddParams}
              onDeleteParams={onDeleteParams}
              onSearch={onSearch}
              hasSelectedData={hasSelectedData}
              onDelete={onDelete}
            />
          ) : (
            <KContainer.View />
          )}

          {_showRightActions && (
            <RightActions
              id={uuid}
              name={name}
              showViewColumns={showViewColumns}
              rightNode={rightNode}
              onRefresh={onRefresh}
              gridRef={gridRef as any}
              onExport={onExport}
              onExportParams={onExportParams}
            />
          )}
        </KContainer.View>
      )}

      <KContainer.View
        id={uuid}
        height={height ?? 400}
        width="100%"
        position="relative"
        className={clsx(`ag-theme-quartz ${classes.sheetDataWrapper}`, {
          'disable-ag-body-viewport': disableBodyViewport
        })}
      >
        {isLoading && <KLoading thickness={3} />}

        <AgGridReact
          ref={gridRef}
          defaultColDef={{ ...defaultColDef.current, ...rest.defaultColDef }}
          rowData={rowData}
          loadingOverlayComponent={KLoading}
          columnTypes={colTypes}
          onSortChanged={e => e.api.refreshHeader()}
          {...options}
        />
      </KContainer.View>
    </>
  );
};
const typedMemo: <M>(c: M) => M = React.memo;
export default typedMemo(AgTable);
