import { snakeCase } from 'lodash';
import { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { InvoiceCategory } from '@constants';
import {
  useFetchConsolContainer,
  useFetchShipmentContainer,
  useFetchApInvoice,
  useFetchConsol,
  useFetchShipment,
  useFetchArInvoice,
  useFetchService,
  useFetchServiceContainer
} from 'hooks';
import { setDocumentTitle } from 'hooks/useDocumentTitle/use-document-title';
import trans from 'translation';
import { RegexManager } from 'utils';
import { capitalizeFirstLetter } from 'utils/regex';

export const useConsolidationBreadcrumbs = (isOcean = true) => {
  const { id, shipmentId, containerId, arInvoiceId, apInvoiceId } =
    useParams<any>();

  const { pathname: path } = useLocation();

  const navigate = useNavigate();

  const { data: arInvoice } = useFetchArInvoice(
    InvoiceCategory.Accounting,
    arInvoiceId
  );
  const { data: apInvoice } = useFetchApInvoice(
    InvoiceCategory.Accounting,
    apInvoiceId
  );
  const { data: consol } = useFetchConsol(id);
  const { data: shipment } = useFetchShipment(shipmentId);
  const { data: container1 } = useFetchConsolContainer(
    containerId,
    id,
    !shipmentId
  );
  const { data: container2 } = useFetchShipmentContainer(
    containerId,
    shipmentId
  );

  const res = useMemo(() => {
    const arr = path
      .split('/')
      .slice(3)
      .filter(i => i);

    const arrLen = arr.length;

    const kind = {
      true: {
        screen: 'Ocean Freight',
        bc: [trans('routes.ocean_freight')]
      },
      false: {
        screen: 'Air Freight',
        bc: [trans('routes.air_freight')]
      }
    };
    const _kind = kind[`${isOcean}`];
    const subBc = arr.map((i, idx) => {
      let title = trans(`routes.${snakeCase(i)}`);

      if (RegexManager.atLeastOneNumber.test(i)) {
        switch (idx) {
          case 1:
            title = consol?.consolNumber ?? '';
            break;

          case 3:
            title = shipmentId
              ? [shipment?.code, shipment?.hblNo].filter(o => !!o).join(' / ')
              : container1?.code ?? '';
            break;

          case 5:
            title = container2?.code ?? '';
            break;

          case 4:
          case 6:
            title = apInvoiceId ? apInvoice?.code ?? '' : arInvoice?.code ?? '';
            break;

          default:
            break;
        }
      }
      return {
        title,
        onPress:
          idx === arrLen - 1
            ? undefined
            : () => {
                idx === 0
                  ? navigate('../', { replace: true })
                  : navigate(
                      `../${Array.from({ length: idx })
                        .map((_, _idx) => arr[_idx + 1])
                        .join('/')}`,
                      { replace: true }
                    );
              }
      };
    });
    const _subBc = [..._kind.bc, ...subBc];
    const _subBcLen = _subBc.length;
    setDocumentTitle(
      `${capitalizeFirstLetter(_subBc[_subBcLen - 2].title)} | ${
        _subBc[_subBcLen - 1].title ?? ''
      }`
    );

    return _subBc;
  }, [
    apInvoice?.code,
    apInvoiceId,
    arInvoice?.code,
    consol?.consolNumber,
    container1?.code,
    container2?.code,
    isOcean,
    navigate,
    path,
    shipment?.code,
    shipment?.hblNo,
    shipmentId
  ]);

  return res;
};

export const useShipmentBreadcrumbs = (isOcean = true) => {
  const { shipmentId, containerId, arInvoiceId, apInvoiceId } =
    useParams<any>();

  const { pathname: path } = useLocation();

  const navigate = useNavigate();

  const { data: arInvoice } = useFetchArInvoice(
    InvoiceCategory.Billing,
    arInvoiceId
  );
  const { data: apInvoice } = useFetchApInvoice(
    InvoiceCategory.Billing,
    apInvoiceId
  );
  const { data: shipment } = useFetchShipment(shipmentId);
  const { data: container } = useFetchShipmentContainer(
    containerId,
    shipmentId
  );

  const res = useMemo(() => {
    const arr = path
      .split('/')
      .slice(3)
      .filter(i => i);

    const arrLen = arr.length;

    const bc = [trans(isOcean ? 'routes.ocean_freight' : 'routes.air_freight')];

    const subBc = arr.map((i, idx) => {
      let title = trans(`routes.${snakeCase(i)}`);

      if (RegexManager.atLeastOneNumber.test(i)) {
        switch (idx) {
          case 1:
            title = [shipment?.code, shipment?.hblNo]
              .filter(o => !!o)
              .join(' / ');
            break;

          case 3:
            title = container?.containerNumber ?? container?.code ?? '';
            break;

          case 4:
            title = apInvoiceId ? apInvoice?.code ?? '' : arInvoice?.code ?? '';
            break;

          default:
            break;
        }
      }
      return {
        title,
        onPress:
          idx === arrLen - 1
            ? undefined
            : () => {
                idx === 0
                  ? navigate('../', { replace: true })
                  : navigate(
                      `../${Array.from({ length: idx })
                        .map((_, _idx) => arr[_idx + 1])
                        .join('/')}`,
                      { replace: true }
                    );
              }
      };
    });

    const _subBc = [...bc, ...subBc];
    const _subBcLen = _subBc.length;
    setDocumentTitle(
      `${capitalizeFirstLetter(_subBc[_subBcLen - 2].title ?? 'Shipment')} | ${
        _subBc[_subBcLen - 1].title
      }`
    );
    return _subBc;
  }, [
    apInvoice?.code,
    apInvoiceId,
    arInvoice?.code,
    container?.code,
    container?.containerNumber,
    isOcean,
    navigate,
    path,
    shipment?.code,
    shipment?.hblNo
  ]);

  return res;
};

export const useServiceBreadcrumbs = () => {
  const { serviceId, serviceContainerId, arServiceId, apServiceId } =
    useParams();

  const { pathname: path } = useLocation();

  const navigate = useNavigate();

  const { data: item } = useFetchService(serviceId);

  const { data: arInvoice } = useFetchArInvoice(
    InvoiceCategory.Service,
    arServiceId
  );

  const { data: apInvoice } = useFetchApInvoice(
    InvoiceCategory.Service,
    apServiceId
  );

  const { data: container } = useFetchServiceContainer(serviceContainerId, {
    serviceId,
    shipmentId: item?.shipmentId
  });

  const res = useMemo(() => {
    const arr = path
      .split('/')
      .slice(3)
      .filter(i => i);

    const arrLen = arr.length;

    const bc = [trans('routes.service')];

    const subBc = arr.map((i, idx) => {
      let title = trans(`routes.${snakeCase(i)}`);

      if (RegexManager.atLeastOneNumber.test(i)) {
        switch (idx) {
          case 0:
            title = item?.code ?? '';
            break;

          case 2:
            title = container?.containerNumber ?? '';
            break;

          case 3:
            title = apServiceId ? apInvoice?.code ?? '' : arInvoice?.code ?? '';
            break;

          default:
            break;
        }
      }

      return {
        title,
        onPress:
          idx === arrLen - 1
            ? undefined
            : () =>
                navigate(
                  `../${Array.from({ length: idx + 1 })
                    .map((_, _idx) => arr[_idx])
                    .join('/')}`,
                  { replace: true }
                )
      };
    });
    const _subBc = [...bc, ...subBc];
    const _subBcLen = _subBc.length;
    setDocumentTitle(
      `${capitalizeFirstLetter(_subBc[_subBcLen - 2].title ?? 'Service')} | ${
        _subBc[_subBcLen - 1].title
      }`
    );
    return _subBc;
  }, [
    apInvoice?.code,
    apServiceId,
    arInvoice?.code,
    container?.containerNumber,
    item?.code,
    navigate,
    path
  ]);

  return res;
};
