import {
  ICellRendererParams,
  ColDef,
  GridApi,
  NewValueParams,
  ValueFormatterParams
} from 'ag-grid-community';
import { cloneDeep, get, isNumber } from 'lodash';
import React from 'react';

import { EventName } from '@constants';
import { Prototype } from 'core';
import trans from 'translation';
import { KButton, KChip, KChipProps, KColors, KContainer, KLabel } from 'uikit';

import UIUtils from './ui';

interface ITableChip extends Omit<KChipProps, 'label'> {
  label?: string;
}

export default class AgUtils {
  static options = {
    chip: () => ({
      cellClass: ({ value: v }: any) => {
        if (v) {
          const _v = v?.code ?? v;
          return `ag-status ag-status-${_v}`;
        }

        return '';
      },
      valueFormatter: ({ value: v }: ValueFormatterParams<any>) => {
        const _v = (v?.name ?? v)?.toLowerCase();
        return _v ? trans(`option.${_v}`).toUpperCase() : '';
      }
      // cellRenderer: ({ value: v }: ICellRendererParams<any>) => {
      //   if (v) {
      //     const val = v?.name ?? v;
      //     const { withOption, width, uppercase, ...rest } = data || {};

      //     return (
      //       <KChip
      //         background={v?.backgroundColor || KColors.palette.blue.w50}
      //         brC={v?.border || KColors.palette.blue.w600}
      //         label={(withOption
      //           ? trans(`option.${val.toLowerCase()}`)
      //           : trans(val.toLowerCase())
      //         ).toUpperCase()}
      //         textTransform={uppercase ? 'uppercase' : 'capitalize'}
      //         color={v?.color || KColors.palette.blue.w600}
      //         width={width}
      //         {...rest}
      //       />
      //     );
      //   }

      //   return null;
      // }
    }),
    chips: (data: {
      uppercase?: boolean;
      width?: number;
      mapLabelData: {
        [key: string]: ITableChip;
      };
      notFoundKey?: string;
    }) => ({
      cellRenderer: ({ value: v }: ICellRendererParams<any>) => {
        let item = v;
        if (v) {
          if (typeof v === 'boolean') {
            item = {
              name: v,
              ...(data?.mapLabelData?.[`${v}`] ??
                data?.mapLabelData?.[`${v}`.toLowerCase()])
            };
          } else {
            item = {
              name: v,
              ...(data?.mapLabelData?.[v] ??
                data?.mapLabelData?.[v.toLowerCase()])
            };
          }
        } else if (data.notFoundKey) {
          item = {
            name: data.notFoundKey,
            ...(data?.mapLabelData?.[`${data.notFoundKey}`] ??
              data?.mapLabelData?.[`${data.notFoundKey}`.toLowerCase()])
          };
        }

        if (item) {
          const { name, label, ...rest } = item;
          return (
            <KChip
              background={item?.backgroundColor || '#DEFFFD'}
              brC={KColors.primary.normal}
              label={label ?? name ?? ''}
              textTransform={data?.uppercase ? 'uppercase' : 'capitalize'}
              color={KColors.primary.normal}
              width={data?.width}
              {...rest}
            />
          );
        }

        return null;
      }
    }),

    number: (defaultValue: any = '', options?: any) => ({
      cellRenderer: ({ value: v }: ICellRendererParams<any>) =>
        isNumber(v)
          ? Prototype.number.formatNumber(v, { ...options })
          : defaultValue
    }),
    currency: (defaultValue: any = '', options?: any) => ({
      cellRenderer: ({ value: v }: ICellRendererParams<any>) => {
        return !Prototype.core.isNullOrUndefined(v) && isNumber(v)
          ? Prototype.number.formatNumber(v, {
              withAbs: v < 0,
              withSpace: true,
              ...options
            })
          : !Prototype.core.isNullOrUndefined(v) &&
            isNumber(parseFloat(v as string))
          ? Prototype.number.formatNumber(v, {
              withAbs: parseFloat(v as string) < 0,
              withSpace: true,
              ...options
            })
          : defaultValue;
      }
    }),

    toggle: (flag = true) => ({
      hide: !flag
    }),

    withMore: (options?: {
      renderItem?: (i: any, index: number) => React.ReactNode;
      onPress?: (d: any[]) => void;
      maxLength?: number;
      onNormalizeData?: (v: any) => string[];
      key?: string;
    }) => {
      const {
        renderItem,
        onPress,
        onNormalizeData,
        maxLength = 2,
        key
      } = options || {};

      return {
        minWidth: maxLength === 1 ? 150 : 230,
        cellRenderer: ({
          value: v,
          column,
          data,
          colDef
        }: ICellRendererParams<any>) => {
          const { headerName = '' } = colDef || {};

          v = onNormalizeData?.(v) ?? v;

          if (v && Array.isArray(v)) {
            const arr = cloneDeep(v);
            const displayArr = arr.slice(0, maxLength);
            const name = column?.getColId() ?? '';
            const id = data?.id ?? 'new';

            const _onPress = (a: any[]) => {
              if (onPress) {
                return onPress(a);
              }

              UIUtils.popup.open({
                title: headerName,
                maxWidth: 'xs',
                touchOutsideToDismiss: true,
                content: () => (
                  <>
                    {(a ?? []).map((i, idx) => {
                      const marginT = idx === 0 ? 0 : '0.75rem';
                      return (
                        <KContainer.View
                          key={`popup-${id}-${name}-${idx}`}
                          dp="flex"
                          center
                          paddingV="0.25rem"
                          paddingH="0.75rem"
                          br="x"
                          brW={1}
                          brC={KColors.warning.normal}
                          marginT={marginT}
                        >
                          <KLabel.Text typo="TextMdNormal">
                            {key ? get(i, key, '') : i}
                          </KLabel.Text>
                        </KContainer.View>
                      );
                    })}
                  </>
                )
              });
            };

            return (
              <KContainer.View row alignItems flexW="wrap" gap="0.25rem">
                {displayArr.map((i, idx) => {
                  if (renderItem) {
                    return renderItem(i, idx);
                  }

                  return (
                    <KContainer.View
                      key={`${id}-${name}-${idx}`}
                      minW={83}
                      height={22}
                      dp="flex"
                      center
                      background={'#FFF5E5'}
                      brC={KColors.warning.normal}
                      br="x"
                      brW={1}
                      paddingH="0.25rem"
                    >
                      <KLabel.Text color={KColors.warning.dark}>
                        {key ? get(i, key, '') : i}
                      </KLabel.Text>
                    </KContainer.View>
                  );
                })}

                {arr.length > maxLength && (
                  <KButton.Icon
                    icon="MoreHoriz"
                    tintColor={KColors.white}
                    background={KColors.primary.normal}
                    br="x"
                    size="sm"
                    onPress={() => _onPress(arr)}
                  />
                )}
              </KContainer.View>
            );
          }

          return null;
        }
      };
    },

    customHeader: (options?: {
      required?: boolean;
      btn?: {
        event?: keyof typeof EventName;
        icon?: string; // PATH OF SVG
      };
      onCellValueChanged?: (event: NewValueParams<any>) => void;
    }): Pick<ColDef<any>, 'headerComponentParams' | 'onCellValueChanged'> => {
      return {
        onCellValueChanged: event => {
          options?.onCellValueChanged?.(event);

          if (options?.btn?.event) {
            event.api.refreshHeader();
          }
        },
        headerComponentParams: (p: any) => {
          const { required = false, btn } = options || {};

          const requiredHTML = required
            ? '<span style="color:red">&nbsp;*</span>'
            : '';

          const {
            event,
            icon = '<path d="M18 6.41 16.59 5 12 9.58 7.41 5 6 6.41l6 6z"></path><path d="m18 13-1.41-1.41L12 16.17l-4.59-4.58L6 13l6 6z"></path>'
          } = btn || {};

          let btnHTML = '';

          if (event) {
            const { api } = p;

            const arr: any[] = [];
            (api as GridApi<any>).forEachNodeAfterFilterAndSort(i => {
              arr.push(i.data);
            });

            const str = JSON.stringify(arr);

            const cb = (e: MouseEvent, name: string, d: any[]) => {
              const _e = new CustomEvent(name, { detail: d });
              document.dispatchEvent(_e);
            };

            btnHTML = `<button class="ag-custom-button" onclick='event.stopPropagation(); event.preventDefault(); (${cb})(event, "${event}", ${str});'><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium" focusable="false" aria-hidden="true" viewBox="0 0 24 24" width="20" height="20" fill="#13a89e" style="font-size: 20px;" data-testid="KeyboardDoubleArrowDownIcon">${icon}</svg></button>`;
          }

          return {
            template: `<div class="ag-cell-label-container" role="presentation">
                <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                    <span ref="eText" class="ag-header-cell-text"></span>
                    ${requiredHTML}
                    <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
                    <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
                    <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
                    <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
                    <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
                    ${btnHTML}
                </div>
            </div>`
          };
        }
      };
    }

    // customHeader: (options?: {
    //   required?: boolean;
    //   btn?: {
    //     icon?: string;
    //     cb?: (event: MouseEvent, d: any) => void;
    //   };
    // }): Pick<ColDef<any>, 'headerComponentParams'> => {
    //   const { required, btn } = options || {};
    //   const { icon, cb } = btn || {};

    //   return {
    //     headerComponentParams: (p: any) => {
    //       let btnHTML = '';

    //       if (!isEmpty(btn)) {
    //         const { api } = p;

    //         const arr: any[] = [];
    //         (api as GridApi<any>).forEachNode(i => {
    //           arr.push(i.data);
    //         });

    //         const str = JSON.stringify(arr);

    //         btnHTML = `<button class="ag-custom-button" onclick='event.stopPropagation(); event.preventDefault(); (${cb})(event, ${str});'><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium" focusable="false" aria-hidden="true" viewBox="0 0 24 24" width="20" height="20" fill="#13a89e" style="font-size: 20px;" data-testid="KeyboardDoubleArrowDownIcon"><path d="M18 6.41 16.59 5 12 9.58 7.41 5 6 6.41l6 6z"></path><path d="m18 13-1.41-1.41L12 16.17l-4.59-4.58L6 13l6 6z"></path></svg></button>`;
    //       }

    //       return {
    //         template: `<div class="ag-cell-label-container" role="presentation">
    //             <div ref="eLabel" class="ag-header-cell-label" role="presentation">
    //                 <span ref="eText" class="ag-header-cell-text"></span>${
    //                   required ? '<span style="color:red">&nbsp;*</span>' : ''
    //                 }
    //                 <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon" aria-hidden="true"></span>
    //                 <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order" aria-hidden="true"></span>
    //                 <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon" aria-hidden="true"></span>
    //                 <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon" aria-hidden="true"></span>
    //                 <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon" aria-hidden="true"></span>
    //                 ${btnHTML}
    //             </div>
    //         </div>`
    //       };
    //     }
    //   };
    // }
  };
}
