import { FirstDataRenderedEvent, IRowNode } from 'ag-grid-community';
import React, { memo, useMemo, useState } from 'react';

import { AdviceType, WebTable } from '@constants';
import { BottomActions } from 'components';
import AgTable from 'containers/AgTable';
import { useFetchAdviceInvoiceList } from 'hooks';
import useAgTable from 'hooks/core/useAgTable';
import { useChargeCodePayment } from 'pages/Accounting/Advice/helpers';
import { UIUtils } from 'utils';

import { colDefs } from './config';

export interface IOtherParams {
  clientId: number;
  excludeIds: number[];
  paymentCurrencyId?: number;
}

export interface IProps {
  adviceType: AdviceType;
  otherParams: IOtherParams;
  isDone?: boolean;
  onEndEditing?: (data: any[]) => void;
}

const InvoiceList = (props: IProps) => {
  const { adviceType, otherParams, isDone, onEndEditing } = props;

  const isPayment = adviceType === AdviceType.Payment;
  const { data, isLoading, refetch } = useFetchAdviceInvoiceList(
    adviceType,
    otherParams
  );

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const {
    mutate: mutateChargeCodePayment,
    isLoading: isLoadingChargeCodePayment
  } = useChargeCodePayment({
    adviceType
  });

  const table = useAgTable({
    name: isPayment
      ? WebTable.LOCAL_PAYMENT_ADVICE_INVOICE
      : WebTable.LOCAL_RECEIVABLE_ADVICE_INVOICE,
    data,
    colDefs: colDefs({ adviceType }),
    onAddParams: {
      hidden: true
    }
  });

  const onSetNodesSelected = useMemo(
    () => (event: FirstDataRenderedEvent<any, any>) => {
      const nodesToSelect: IRowNode<any>[] = [];
      event.api.forEachNode(node => {
        if (node.data && otherParams.excludeIds.includes(node.data.id)) {
          nodesToSelect.push(node);
        }
      });
      event.api.setNodesSelected({ nodes: nodesToSelect, newValue: true });
    },
    [otherParams.excludeIds]
  );

  return (
    <>
      <AgTable
        {...table}
        showSearch
        onRefresh={refetch}
        isLoading={isLoading}
        onRowSelected={event => {
          const _selectedRows = event.api.getSelectedRows();
          setSelectedRows(_selectedRows);
        }}
        onFirstDataRendered={onSetNodesSelected}
      />

      <BottomActions
        btnProps={[
          {
            title: 'Add',
            icon: 'Add',
            isLoading: isLoadingChargeCodePayment,
            disabled: !selectedRows.length || isDone,
            onPress: () => {
              mutateChargeCodePayment(
                {
                  invoiceIds: selectedRows.map(i => i.id)
                },
                {
                  onSuccess(_data) {
                    onEndEditing?.(_data);
                    UIUtils.popup.dismiss();
                  }
                }
              );
            }
          }
        ]}
      />
    </>
  );
};

export default memo(InvoiceList);
