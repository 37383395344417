import React from 'react';

import {
  useCUDChargeCodeUnit,
  useCUDFlight,
  useCUDIncoterm,
  useCUDShipmentType,
  useCUDVessel,
  useCUDCarrierPolicy,
  useCUDContainerTypeSize,
  useCUDPackageType,
  useCUDDangerousPackingGroups,
  useCUDDangerousClass,
  useCUDChargeCode,
  useCUDChargeCodeCategory,
  useCUDChargeCodeSubCategory,
  useCUDHandlingType,
  useCUDDeliveryMode,
  useCUDPenaltyType,
  useAlertMutationEnhancer
} from 'hooks';
import { useCUDQuotationTemplate } from 'hooks/ssLibrary/sales';
import trans from 'translation';
import { UIUtils } from 'utils';

import ChargeCodeForm from './Accounting/ChargeCode/Form';
import ChargeCodeCategoryForm from './Accounting/ChargeCodeCategory/Form';
import ChargeCodeSubCategoryForm from './Accounting/ChargeCodeSubCategory/Form';
import ChargeCodeUnitForm from './Accounting/ChargeCodeUnit/Form';
import CarrierPolicyForm from './Freight/CarrierPolicy/Form';
import ContainerDangerousClassForm from './Freight/ContainerDangerousClass/Form';
import ContainerDangerousPackingGroupsForm from './Freight/ContainerDangerousPackingGroups/Form';
import ContainerSizeForm from './Freight/ContainerTypeSize/Form';
import FlightForm from './Freight/Flight/Form';
import IncotermForm from './Freight/Incoterm/Form';
import PackageTypeForm from './Freight/PackageType/Form';
import PenaltyTypeForm from './Freight/PenaltyType/Form';
import ShipmentTypeForm from './Freight/ShipmentType/Form';
import VesselForm from './Freight/Vessel/Form';
import QuotationForm from './Sales/Quotation/Form';
import FormChargeCode, { IBodyField } from './Sales/Quotation/Form.ChargeCode';
import DeliveryModeForm from './Service/DeliveryMode/Form';
import HandLingTypeForm from './Service/HandlingType/Form';

export const onShowVessel = (v?: any) => {
  UIUtils.popup.open({
    title: trans('vessel'),
    maxWidth: 'md',
    content: () => <VesselForm item={v} />
  });
};

export const useDeleteVesselsHelper = () => {
  const { deleteMutation: mutation } = useCUDVessel();

  return useAlertMutationEnhancer({ mutation });
};

export const onShowFlight = (v?: any) => {
  UIUtils.popup.open({
    title: trans('flight'),
    maxWidth: 'md',
    content: () => <FlightForm item={v} />
  });
};

export const useDeleteFlightsHelper = () => {
  const { deleteMutation: mutation } = useCUDFlight();

  return useAlertMutationEnhancer({ mutation });
};

export const onShowChargeCodeUnit = (v?: any) => {
  UIUtils.popup.open({
    title: trans('charge_code_unit'),
    maxWidth: 'xl',
    content: () => <ChargeCodeUnitForm item={v} />
  });
};

export const useDeleteChargeCodeUnitsHelper = () => {
  const { deleteMutation: mutation } = useCUDChargeCodeUnit();

  return useAlertMutationEnhancer({ mutation });
};

export const onShowShipmentType = (v?: any) => {
  UIUtils.popup.open({
    title: trans('shipment_type'),
    maxWidth: 'md',
    content: () => <ShipmentTypeForm item={v} />
  });
};

export const useDeleteShipmentTypesHelper = () => {
  const { deleteMutation: mutation } = useCUDShipmentType();

  return useAlertMutationEnhancer({ mutation });
};

export const onShowIncoterm = (v?: any) => {
  UIUtils.popup.open({
    title: trans('incoterm'),
    maxWidth: 'md',
    content: () => <IncotermForm item={v} />
  });
};

export const useDeleteIncotermsHelper = () => {
  const { deleteMutation: mutation } = useCUDIncoterm();

  return useAlertMutationEnhancer({ mutation });
};

export const onShowCarrierPolicy = (v?: any) => {
  UIUtils.popup.open({
    title: trans('carrier_policy'),
    maxWidth: 'lg',
    content: () => <CarrierPolicyForm item={v} />
  });
};

export const useDeleteCarrierPolicyHelper = () => {
  const { deleteMutation: mutation } = useCUDCarrierPolicy();

  return useAlertMutationEnhancer({ mutation });
};

export const onShowContainerSize = (v?: any) => {
  UIUtils.popup.open({
    title: trans('container_type_size'),
    maxWidth: 'md',
    content: () => <ContainerSizeForm item={v} />
  });
};

export const useDeleteContainerTypeSizeHelper = () => {
  const { deleteMutation: mutation } = useCUDContainerTypeSize();

  return useAlertMutationEnhancer({ mutation });
};

export const onShowPackageType = (v?: any) => {
  UIUtils.popup.open({
    title: trans('package_type'),
    maxWidth: 'md',
    content: () => <PackageTypeForm item={v} />
  });
};

export const useDeletePackageType = () => {
  const { deleteMutation: mutation } = useCUDPackageType();

  return useAlertMutationEnhancer({ mutation });
};

export const onShowDangerousPackingGroups = (v?: any) => {
  UIUtils.popup.open({
    title: trans('container_dangerous_packing_groups'),
    maxWidth: 'md',
    content: () => <ContainerDangerousPackingGroupsForm item={v} />
  });
};

export const useDeleteDangerousPackingGroups = () => {
  const { deleteMutation: mutation } = useCUDDangerousPackingGroups();

  return useAlertMutationEnhancer({ mutation });
};

export const onShowDangerousClass = (v?: any) => {
  UIUtils.popup.open({
    title: trans('container_dangerous_class'),
    maxWidth: 'md',
    content: () => <ContainerDangerousClassForm item={v} />
  });
};

export const useDeleteDangerousClass = () => {
  const { deleteMutation: mutation } = useCUDDangerousClass();

  return useAlertMutationEnhancer({ mutation });
};

export const onShowChargeCode = (v?: any) => {
  UIUtils.popup.open({
    title: trans('charge_code'),
    maxWidth: 'lg',
    content: () => <ChargeCodeForm item={v} />
  });
};

export const useDeleteChargeCodeHelper = () => {
  const { deleteMutation: mutation } = useCUDChargeCode();

  return useAlertMutationEnhancer({ mutation });
};

export const onShowChargeCodeCategory = (v?: any) => {
  UIUtils.popup.open({
    title: trans('charge_code_category'),
    maxWidth: 'md',
    content: () => <ChargeCodeCategoryForm item={v} />
  });
};

export const useDeleteChargeCodeCategoryHelper = () => {
  const { deleteMutation: mutation } = useCUDChargeCodeCategory();

  return useAlertMutationEnhancer({ mutation });
};

export const onShowChargeCodeSubCategory = (v?: any) => {
  UIUtils.popup.open({
    title: trans('charge_code_category'),
    maxWidth: 'md',
    content: () => <ChargeCodeSubCategoryForm item={v} />
  });
};

export const useDeleteChargeCodeSubCategoryHelper = () => {
  const { deleteMutation: mutation } = useCUDChargeCodeSubCategory();

  return useAlertMutationEnhancer({ mutation });
};

export const onShowQuotationTemplate = (
  source: 'quotation' | 'invoice',
  v?: any
) => {
  UIUtils.popup.open({
    title: trans(`${source}_template`),
    maxWidth: 'xl',
    content: () => <QuotationForm item={v} source={source} />
  });
};

export const useDeleteQuotationTemplateHelper = (
  template: 'quotation' | 'invoice'
) => {
  const { deleteMutation: mutation } = useCUDQuotationTemplate(template);

  return useAlertMutationEnhancer({ mutation });
};

export const onShowQuotationChargeCodeList = (
  v: IBodyField,
  onAddChargeCode: (v: any[]) => void
) => {
  UIUtils.popup.open({
    title: trans('charge_code'),
    maxWidth: 'xl',
    content: () => {
      return <FormChargeCode bodyField={v} onAddChargeCode={onAddChargeCode} />;
    }
  });
};

export const onShowHandlingType = (v?: any) => {
  UIUtils.popup.open({
    title: trans('handling_type'),
    maxWidth: 'md',
    content: () => <HandLingTypeForm item={v} />
  });
};

export const useDeleteHandlingTypeHelper = () => {
  const { deleteMutation: mutation } = useCUDHandlingType();

  return useAlertMutationEnhancer({ mutation });
};

export const onShowDeliveryMode = (v?: any) => {
  UIUtils.popup.open({
    title: trans('delivery_mode'),
    maxWidth: 'md',
    content: () => <DeliveryModeForm item={v} />
  });
};

export const useDeleteDeliveryModeHelper = () => {
  const { deleteMutation: mutation } = useCUDDeliveryMode();

  return useAlertMutationEnhancer({ mutation });
};

export const onShowPenaltyType = (item?: any) => {
  UIUtils.popup.open({
    title: trans('penalty_type'),
    maxWidth: 'md',
    content: () => <PenaltyTypeForm item={item} />
  });
};

export const useDeletePenaltyTypeHelper = () => {
  const { deleteMutation: mutation } = useCUDPenaltyType();

  return useAlertMutationEnhancer({ mutation });
};
