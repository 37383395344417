import { ColDef } from 'ag-grid-community';
import { omit, upperFirst } from 'lodash';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import * as yup from 'yup';

import { ClientKind, ENDPOINTS, generateOptions, WebTable } from '@constants';
import AgTable from 'containers/AgTable';
import { useGenerateClient, useResolverForm } from 'hooks';
import useAgTable from 'hooks/core/useAgTable';
import trans from 'translation';
import {
  KGrid,
  KInput,
  KListItem,
  KForm,
  KButton,
  KContainer,
  KLabel
} from 'uikit';
import { UIUtils, ValidationUtils } from 'utils';

import { requireClientName, requireTaxId } from '../helpers';

interface IFormData {
  country: any;
  isPerson: boolean;
  taxId: string;
  clientName: string;
}

interface IProps {
  callbackFn?: (v: any) => void;
}

const schema = yup.object().shape({
  country: ValidationUtils.requiredAny(),
  taxId: yup
    .string()
    .nullable()
    .test('taxIdClient', trans('validation.required.default'), function (v) {
      const country = this.parent.country;
      const isPerson = this.parent.isPerson;

      const isVn = country?.code === 'VN';
      const isCorporate = isPerson === false;
      // VN và isCorporate => bắt buộc nhập taxID
      return !(isVn && isCorporate) || !!v;
    }),
  clientName: yup
    .string()
    .nullable()
    .test(
      'clientName',
      trans('validation.required.default'),
      function (v: string) {
        const country = this.parent.country;
        const isPerson = this.parent.isPerson;

        const isPersonal = isPerson === true;
        const isVn = country?.code === 'VN';
        return !(!isVn || (isVn && isPersonal)) || !!v;
      }
    )
});

const FormNewClient = ({ callbackFn }: IProps) => {
  const {
    mutate: generateClient,
    isLoading: isLoadingGenerateClient,
    data: dataClient = []
  } = useGenerateClient();

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        country: null,
        isPerson: false,
        taxId: '',
        clientName: ''
      }
    }
  });

  const [isPerson, country] = useWatch({
    control: methods.control,
    name: ['isPerson', 'country']
  });

  const colDefs = useMemo<ColDef<any>[]>(() => {
    return [
      {
        headerName: trans('code'),
        field: 'code'
      },
      {
        headerName: trans('name'),
        field: 'name'
      },
      {
        headerName: trans('sales_person'),
        field: 'salesPersonNames'
      },
      {
        headerName: trans('country'),
        field: 'countryName'
      },
      {
        headerName: trans('corporate'),
        field: 'isPerson',
        maxWidth: 100,
        cellRendererSelector: params => {
          const isChecked = !params.value; // Logic đảo ngược boolean
          return {
            component: 'agCheckboxCellRenderer', // Sử dụng checkbox renderer mặc định của AG Grid
            params: { value: isChecked } // Truyền giá trị boolean ngược vào
          };
        },
        headerClass: 'text-center',
        cellClass: 'text-center'
      },
      {
        headerName: trans('status'),
        field: 'status'
      }
    ];
  }, []);

  const onFormValid = useCallback(
    (dataForm: IFormData) => {
      generateClient(
        {
          taxId: dataForm?.taxId?.trim(),
          name: dataForm?.clientName?.trim()
        },
        {
          onSuccess(data) {
            if (!data.length) {
              UIUtils.popup.dismiss();
              callbackFn && callbackFn?.(dataForm);
            }
          }
        }
      );
    },
    [callbackFn, generateClient]
  );

  const table = useAgTable({
    name: WebTable.FORM_NEW_CLIENT,
    data: dataClient,
    colDefs,
    onAddParams: {
      hidden: true
    },
    onDeleteParams: {
      hidden: true
    }
  });

  return (
    <>
      <KForm onSubmit={methods.handleSubmit(onFormValid)}>
        <KGrid.Container>
          <KGrid.Item xs={3}>
            <Controller
              name="country"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.Autocomplete
                    {...field}
                    label={trans('country')}
                    getOptionLabel={(o: any) =>
                      `${o.name} (${o.phoneCountryCode})`
                    }
                    apiURL={ENDPOINTS.country()}
                    inputProps={{
                      required: true,
                      message: error?.message
                    }}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={1.5}>
            <Controller
              name="isPerson"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KListItem.RadioGroup
                    containerStyle={{ marginTop: '0.75rem' }}
                    data={generateOptions(ClientKind).map(i => ({
                      id: i.key,
                      label: upperFirst(i.label.toLowerCase()),
                      checked:
                        i.key === ClientKind.Personal
                          ? field.value
                          : !field.value,
                      onChange: v => {
                        if (v) {
                          methods.setValue(
                            'isPerson',
                            i.key === ClientKind.Personal
                          );
                        }
                      }
                    }))}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="taxId"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.TextField
                    {...field}
                    required={requireTaxId(country, isPerson)}
                    label={trans('tax_id')}
                    message={error?.message}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={3}>
            <Controller
              name="clientName"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.TextField
                    {...field}
                    required={requireClientName(country, isPerson)}
                    label={trans('client_name')}
                    message={error?.message}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={1.5} mt="0.70rem">
            <KButton.Solid
              icon="ElectricBoltOutlined"
              title={trans('submit')}
              type="submit"
              isLoading={isLoadingGenerateClient}
            />
          </KGrid.Item>
        </KGrid.Container>
      </KForm>

      {!!dataClient.length && (
        <KContainer.View marginT={'0.75rem'}>
          <KLabel.Error typo="TextNmMedium">
            {trans('message.err_new_client')}
          </KLabel.Error>
          <KContainer.View marginT={'0.5rem'}>
            <AgTable
              {...omit(table, 'datasource')}
              singleClickEdit
              pagination={true}
              onAddParams={{
                hidden: true
              }}
              sortable={false}
              showViewColumns={false}
              onExportParams={{ hidden: true }}
              onRefresh={undefined}
            />
          </KContainer.View>
        </KContainer.View>
      )}
    </>
  );
};

export default memo(FormNewClient);
