import { usePrevious } from '@dwarvesf/react-hooks';
import {
  useMutation,
  useQueryClient,
  QueryKey,
  QueryFunction,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
  UseMutationOptions,
  MutateOptions,
  MutationKey
} from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { PAGE_SIZE_DEFAULT } from '@constants';

import { useUser } from '../auth';

export const useMutationEnhancer = <
  TData = unknown,
  TVariables = unknown,
  TContext = unknown,
  TError = unknown
>(
  options: UseMutationOptions<TData, TError, TVariables, TContext> & {
    mutationKeys?: Array<MutationKey>;
  }
) => {
  const queryClient = useQueryClient();

  const { mutate, mutateAsync, ...rest } = useMutation<
    TData,
    TError,
    TVariables,
    TContext
  >({
    ...options,
    onSuccess: (data, variables, context) => {
      if (options.mutationKeys) {
        options.mutationKeys.forEach(key => {
          queryClient.invalidateQueries<TContext>(key, {
            type: 'active'
          });
        });
      }
      options?.onSuccess?.(data, variables, context);
    }
  });

  const mutateEnhancer = useCallback(
    (
      v?: TVariables,
      o?: MutateOptions<TData, TError, TVariables, TContext> | undefined
    ) => {
      mutate(v as TVariables, o);
    },
    [mutate]
  );

  const mutateAsyncEnhancer = useCallback(
    (
      v?: TVariables,
      o?: MutateOptions<TData, TError, TVariables, TContext> | undefined
    ): Promise<TData> => {
      return mutateAsync(v as TVariables, o);
    },
    [mutateAsync]
  );

  return {
    mutate: mutateEnhancer,
    mutateAsync: mutateAsyncEnhancer,
    ...rest
  };
};

export const useQueryEnhancer = <
  TData = unknown,
  TQueryFnData = unknown,
  TQueryKey extends QueryKey = QueryKey,
  TError = unknown
>(
  options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
) => {
  const props = useQuery<TQueryFnData, TError, TData, TQueryKey>(options);

  return { ...props, isLoading: props.isInitialLoading || props.isFetching };
};

export const useInfiniteEnhancer = <
  TData = unknown,
  TQueryParams = unknown,
  TQueryKey extends QueryKey = QueryKey,
  TError = unknown
>(
  options: Omit<
    UseInfiniteQueryOptions<
      TQueryParams,
      TError,
      TData,
      TQueryParams,
      TQueryKey
    >,
    'queryKey' | 'queryFn'
  > & {
    queryKey: TQueryKey;
    queryFn: QueryFunction<TQueryParams, TQueryKey>;
  }
) => {
  const { user } = useUser();
  const prevUser = usePrevious(user);

  const { queryFn, queryKey, ...restOptions } = options;
  const [isRefreshing, setIsRefreshing] = useState(false);
  const queryClient = useQueryClient();
  const {
    data,
    isFetchingNextPage,
    isRefetching,
    refetch,
    fetchNextPage,
    ...rest
  } = useInfiniteQuery<TQueryParams, TError, TData, TQueryKey>(
    queryKey,
    queryFn,
    {
      suspense: true,
      retry: false,
      getNextPageParam: (lastPage: any, allPage) => {
        return lastPage.length < PAGE_SIZE_DEFAULT ? undefined : allPage.length;
      },
      ...restOptions
    }
  );

  useEffect(() => {
    if (!isRefetching && isRefreshing) {
      setIsRefreshing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefetching]);

  const results = useMemo(() => {
    if (data?.pages) {
      return data.pages.reduce((prev: TData[], curr: any) => {
        return prev.concat(curr);
      }, [] as TData[]);
    }
    return [];
  }, [data?.pages]);

  const onRefresh = useCallback(() => {
    queryClient.setQueriesData(queryKey, (allData: any) => {
      return {
        pageParams: [allData?.pageParams?.[0]],
        pages: [allData?.pages?.[0]]
      };
    });
    setIsRefreshing(true);
    refetch();
  }, [queryKey, queryClient, refetch]);

  useEffect(() => {
    if (prevUser?.account?.id !== user?.account?.id) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.account?.id, prevUser?.account?.id]);

  const onEndReached = useCallback(() => {
    if (!isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, isFetchingNextPage]);

  return {
    ...rest,
    isFetchingNextPage,
    data: results,
    isRefreshingEnhancer: isRefreshing,
    isRefetching,
    onRefreshEnhancer: onRefresh,
    onEndReached,
    fetchNextPage
  };
};
