import React, { memo, useCallback, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import {
  DATE_FORMAT_SHORT,
  ENDPOINTS,
  StaffStatus,
  TableName,
  WebTable
} from '@constants';
import DataTable from 'containers/DataTable';
import { useDataTable, useGlobalTable, useNewButton } from 'hooks';
import { useDocumentTitle } from 'hooks/useDocumentTitle/use-document-title';
import trans from 'translation';
import { KBreadcrumbs, KColors, KContainer, KImage, KLabel } from 'uikit';
import { TableUtils } from 'utils';

import { useDeleteHelper } from './helpers';

const key = 'staff';

const Staff = () => {
  const navigate = useNavigate();

  useDocumentTitle('Administration | Staff');

  const { onDelete, isLoading } = useDeleteHelper();

  const columnsFunc = useCallback(
    (data: any[]) => {
      return [
        {
          label: trans('action'),
          name: 'action',
          options: TableUtils.options.actionWithMenuList(data, item => [
            {
              title: trans('edit'),
              icon: 'EditOutlined',
              onPress: () => navigate(`${item.id}`)
            },
            {
              title: trans('delete'),
              icon: 'Delete',
              onPress: () => onDelete?.([item])
            }
          ])
        },
        {
          label: ' ',
          name: 'avatar',
          options: {
            ...TableUtils.pureBaseOptions({
              textAlign: 'center',
              maxWidth: 24
            }),
            customBodyRender: (v: string | null) => {
              if (v) {
                return <KImage.Avatar size="xs" source={v} />;
              }
              return '';
            }
          }
        },
        {
          label: trans('staff_code'),
          name: 'code',
          options: {
            customBodyRender: (v: string, rowData: any) => {
              const item = data?.[rowData.rowIndex];
              return (
                <KLabel.Text onPress={() => navigate(`${item.id}`)}>
                  {v}
                </KLabel.Text>
              );
            }
          }
        },
        {
          label: trans('staff_name'),
          name: 'fullName',
          options: {
            ...TableUtils.options.base2XLg,
            customBodyRender: (v: string, rowData: any) => {
              const item = data?.[rowData.rowIndex];
              return (
                <KLabel.Text onPress={() => navigate(`${item.id}`)}>
                  {v}
                </KLabel.Text>
              );
            }
          }
        },
        {
          label: trans('branches'),
          name: 'branches',
          options: TableUtils.options.withMoreIcon({
            key: 'name'
          })
        },
        {
          label: trans('status'),
          name: 'staffStatus',
          options: TableUtils.options.chips({
            uppercase: true,
            width: 90,
            mapLabelData: {
              [StaffStatus.Active]: {
                color: KColors.black,
                background: KColors.primary.normal
              },
              [StaffStatus.Inactive]: {
                color: KColors.black,
                background: KColors.palette.warning.w400,
                brC: KColors.palette.warning.w400
              },
              [StaffStatus.Leave]: {
                color: KColors.black,
                background: KColors.palette.gray.w50,
                brC: KColors.palette.gray.w50
              }
            }
          })
        },
        {
          label: trans('email'),
          name: 'email'
        },
        {
          label: trans('mobile_phone'),
          name: 'mobilePhone'
        },
        {
          label: trans('telephone'),
          name: 'telephone'
        },
        {
          label: trans(`${key}.citizen_id_s`),
          name: 'citizenId'
        },
        {
          label: trans('place_of_issue'),
          name: 'placeOfIssue',
          options: TableUtils.options.baseXLg
        },
        {
          label: trans('date_of_issue'),
          name: 'dateOfIssue',
          options: TableUtils.options.date(DATE_FORMAT_SHORT)
        },
        {
          label: trans('gender'),
          name: 'gender'
        },
        {
          label: trans('date_of_birth'),
          name: 'birthdate',
          options: TableUtils.options.date(DATE_FORMAT_SHORT)
        },
        {
          label: trans('address'),
          name: 'address',
          options: TableUtils.options.base3XLg
        },
        {
          label: trans('tax_id'),
          name: 'taxId'
        }
      ];
    },
    [navigate, onDelete]
  );

  const otherParams = useMemo(() => {
    return {
      includeFields: ['branches']
    };
  }, []);

  const table = useDataTable({
    name: WebTable.STAFF,
    tableName: TableName.STAFF,
    apiURL: ENDPOINTS.staff(),
    columnsFunc,
    otherParams
  });

  useGlobalTable(table);

  const onAdd = useCallback(() => {
    navigate('new');
  }, [navigate]);

  const addButton = useNewButton({
    onPress: onAdd
  });

  return (
    <>
      <KBreadcrumbs
        hasBackIcon={false}
        breadcrumbs={[trans('routes.administration'), trans('routes.staff')]}
      />

      <KContainer.Card margin="0.75rem" marginT="0.25rem">
        <DataTable
          {...table}
          onAdd={onAdd}
          onDelete={onDelete}
          isModifying={isLoading}
        />
      </KContainer.Card>

      {addButton}

      <Outlet />
    </>
  );
};

export default memo(Staff);
