import { set, filter, includes } from 'lodash';

import {
  AdviceType,
  ARInvoiceStatus,
  ENDPOINTS,
  INVOICE_INCLUDE_FIELDS,
  PAGE_SIZE_DEFAULT_MAX,
  QUERY_KEYS
} from '@constants';
import APIManager from '@services';
import { Prototype } from 'core';
import { kind } from 'pages/Accounting/Advice/helpers';
import { IOtherParams } from 'pages/Accounting/Advice/TableDetail/InvoiceList';

import { usePageCUDMutationEnhancer, useQueryEnhancer } from '../core';

export const useCUDAdvice = (adviceType: AdviceType, method?: 'DELETE') => {
  const _kind = kind[adviceType];

  return usePageCUDMutationEnhancer<any>({
    endPoint: _kind.endPoint,
    webTable: _kind.webTable,
    mutationKeys: method ? [] : [[QUERY_KEYS.accountingAdvice]]
  });
};

export const useFetchAdvice = (
  adviceType: AdviceType,
  id?: string | number
) => {
  const isPayment = adviceType === AdviceType.Payment;
  const _kind = kind[adviceType];

  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.accountingAdvice, adviceType, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: isPayment
          ? ENDPOINTS.paymentAdvice(':id', { id })
          : ENDPOINTS.receivableAdvice(':id', { id })
      });

      if (res.data) {
        const { arInvoiceIds, apInvoiceIds, arChargeCodeIds, apChargeCodeIds } =
          res.data;
        const invoiceIds = (arInvoiceIds || apInvoiceIds) ?? [];
        const chargeCodeIds = (arChargeCodeIds || apChargeCodeIds) ?? [];

        let adviceDetails = [];
        let adviceDetailsClientSelected = [];

        if (invoiceIds.length > 0) {
          const adviceRes = await APIManager.request({
            url: ENDPOINTS.advice(_kind.url, _kind.path),
            body: {
              // apInvoiceIds, arInvoiceIds
              [`${_kind.type}InvoiceIds`]: invoiceIds
            }
          });

          adviceDetails = adviceRes.data?.data ?? [];
          adviceDetailsClientSelected = filter(adviceDetails, item => {
            return includes(chargeCodeIds, item.id);
          });
        }

        return {
          ...res.data,
          adviceDetails,
          adviceDetailsClientSelected
        };
      }

      return undefined;
    },
    enabled: !!id
  });
};

export const useFetchAdviceInvoiceList = (
  adviceType: AdviceType,
  params?: IOtherParams
) => {
  const isPayment = adviceType === AdviceType.Payment;
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.invoiceAdvice, adviceType, JSON.stringify(params)],
    queryFn: async () => {
      const res = await APIManager.request({
        url: isPayment ? ENDPOINTS.apInvoice2() : ENDPOINTS.arInvoice2(),
        body: {
          size: PAGE_SIZE_DEFAULT_MAX,
          includeFields: INVOICE_INCLUDE_FIELDS,
          isAdviceCreating: true,
          clientId: params?.clientId,
          paymentCurrencyId: params?.paymentCurrencyId
        }
      });

      return (res.data?.data ?? []).map((i: any) => {
        const invoiceStatus = {
          ...(isPayment ? i.apInvoiceStatus : i.arInvoiceStatus)
        };

        const now = Prototype.date.now().startOf('day');
        const dueDate = Prototype.date
          .toMoment(i.dueDate, 'YYYY-MM-DD')
          ?.startOf('day');

        const isOverdue =
          dueDate?.isBefore(now) &&
          invoiceStatus?.code !== ARInvoiceStatus.COMPLETE;

        if (isOverdue) {
          set(invoiceStatus, 'name', 'OVERDUE');
        }

        return {
          ...i,
          checked: false,
          amount: 0,
          invoiceStatus
        };
      });
    }
  });
};
