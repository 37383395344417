import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { GroupGeneralService } from '@constants';
import Service from 'pages/Service';
import ServiceLayout from 'pages/Service/DetailsLayout';

import { IAdminRoute } from './helpers';

const ServiceGeneral = loadable(() =>
  pMinDelay(import('pages/Service/General'), 200)
);

const CustomsLayout = loadable(() =>
  pMinDelay(import('pages/Service/Customs/DetailsLayout'), 200)
);
const CustomsGeneral = loadable(() =>
  pMinDelay(import('pages/Service/Customs/General'), 200)
);
const CustomsWip = loadable(() =>
  pMinDelay(import('pages/Service/Customs/Wip'), 200)
);
const CustomsCommercialInvoice = loadable(() =>
  pMinDelay(import('pages/Service/Customs/CommercialInvoice'), 200)
);

const TransportLayout = loadable(() =>
  pMinDelay(import('pages/Service/Transport/DetailsLayout'), 200)
);
const TransportGeneral = loadable(() =>
  pMinDelay(import('pages/Service/Transport/Routing'), 200)
);
const TransportWip = loadable(() =>
  pMinDelay(import('pages/Service/Transport/Wip'), 200)
);

// const HandlingLayout = loadable(() =>
//   pMinDelay(import('pages/Service/Handling/DetailsLayout'), 200)
// );
// const HandlingGeneral = loadable(() =>
//   pMinDelay(import('pages/Service/Handling/Service'), 200)
// );
// const HandlingWip = loadable(() =>
//   pMinDelay(import('pages/Service/Handling/Wip'), 200)
// );

const ServiceContainer = loadable(() =>
  pMinDelay(import('pages/Service/Container'), 200)
);
const ServiceContainerDetails = loadable(() =>
  pMinDelay(import('pages/Service/Container/Details'), 200)
);

const ServiceBilling = loadable(() =>
  pMinDelay(import('pages/Service/_Billing'), 200)
);
// const ServiceApInvoiceDetails = loadable(() =>
//   pMinDelay(import('pages/Service/Billing/Invoices/AP/Details'), 200)
// );
// const ServiceArInvoiceDetails = loadable(() =>
//   pMinDelay(import('pages/Service/Billing/Invoices/AR/Details'), 200)
// );
const ServiceApInvoiceDetails = loadable(() =>
  pMinDelay(import('pages/Service/_Billing/Ap/Details'), 200)
);
const ServiceArInvoiceDetails = loadable(() =>
  pMinDelay(import('pages/Service/_Billing/Ar/Details'), 200)
);

const ServiceAlertNote = loadable(() =>
  pMinDelay(import('pages/Service/AlertNote'), 200)
);

const ServiceEmailDoc = loadable(() =>
  pMinDelay(import('pages/Service/EmailEdoc'), 200)
);

const ServiceLog = loadable(() =>
  pMinDelay(import('pages/Service/LogWrapper'), 200)
);

const serviceRoutes: IAdminRoute = {
  name: 'Service',
  path: 'service',
  icon: 'local_shipping',
  menu: [GroupGeneralService.GENERAL_SERVICE_DETAIL_LIST],
  noChildren: true,
  children: [
    {
      index: true,
      element: <Service />
    },
    {
      path: 'new',
      element: <ServiceLayout key="new" />,

      children: [
        {
          index: true,
          element: <ServiceGeneral />
        }
      ]
    },
    {
      path: ':serviceId',
      element: <ServiceLayout key=":serviceId" />,

      children: [
        {
          index: true,
          element: <ServiceGeneral />
        },
        {
          path: 'customs',
          element: <CustomsLayout />,

          children: [
            {
              index: true,
              element: <CustomsGeneral />
            },
            {
              path: 'commercial-invoice',
              element: <CustomsCommercialInvoice />
            },
            {
              path: 'wip',
              element: <CustomsWip />
            }
          ]
        },
        {
          path: 'transport',
          element: <TransportLayout />,

          children: [
            {
              index: true,
              element: <TransportGeneral />
            },
            {
              path: 'wip',
              element: <TransportWip />
            }
          ]
        },
        // {
        //   path: 'handling',
        //   element: <HandlingLayout />,
        //   children: [
        //     {
        //       index: true,
        //       element: <HandlingGeneral />
        //     },
        //     {
        //       path: 'wip',
        //       element: <HandlingWip />
        //     }
        //   ]
        // },
        // {
        //   path: 'handling',
        //   element: <ServiceHandling />
        // },
        // {
        //   path: 'containers-packing',
        //   element: <ServiceContainer />
        // },
        {
          path: 'containers-packing',

          children: [
            {
              index: true,
              element: <ServiceContainer />
            },
            {
              path: 'new',
              element: <ServiceContainerDetails key="new" />
            },
            {
              path: ':serviceContainerId',
              element: <ServiceContainerDetails key=":serviceContainerId" />
            }
          ]
        },
        {
          path: 'billing',

          children: [
            {
              index: true,
              element: <ServiceBilling />
            },
            {
              path: 'ar',
              children: [
                {
                  index: true,
                  element: <Navigate to="../" />
                },
                {
                  path: 'new',
                  element: <ServiceArInvoiceDetails key="new" />
                },
                {
                  path: ':arServiceId',
                  element: <ServiceArInvoiceDetails key=":arServiceId" />
                }
              ]
            },
            {
              path: 'ap',
              children: [
                {
                  index: true,
                  element: <Navigate to="../" />
                },
                {
                  path: 'new',
                  element: <ServiceApInvoiceDetails key="new" />
                },
                {
                  path: ':apServiceId',
                  element: <ServiceApInvoiceDetails key=":apServiceId" />
                }
              ]
            }
          ]
        },
        {
          path: 'document',
          element: <ServiceEmailDoc />
        },
        {
          path: 'alert-note',
          element: <ServiceAlertNote />
        },
        {
          path: 'history',
          element: <ServiceLog />,
          code: [GroupGeneralService.GENERAL_SERVICE_LOG_LIST]
        }
      ]
    }
  ]
};

export default serviceRoutes;
