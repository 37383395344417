import { isEmpty } from 'lodash';
import { ILinkedChartOfAccountResponse } from 'response-dto';

import {
  ENDPOINTS,
  LinkedAccountCode,
  PAGE_SIZE_DEFAULT_MAX,
  QUERY_KEYS,
  WebTable
} from '@constants';
import APIManager from '@services';
import { sortSelected } from 'utils/common';

import {
  useCUDMutationEnhancer,
  useMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useCUDClassification = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.accountingChartOfAccountClassification(),
    webTable: WebTable.CLASSIFICATION,
    dependentWebTable: [WebTable.CHART_OF_ACCOUNTS]
  });
};

export const useCUDChartOfAccount = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.accountingChartOfAccount(),
    webTable: WebTable.CHART_OF_ACCOUNTS
  });
};

export const useFetchLinkedChartOfAccount = (isAccounting = false) => {
  return useQueryEnhancer<ILinkedChartOfAccountResponse | null>({
    queryKey: [
      isAccounting
        ? QUERY_KEYS.accountingLinkedChartOfAccount
        : QUERY_KEYS.linkedChartOfAccount
    ],
    queryFn: async () => {
      const { data } = await APIManager.request({
        url: isAccounting
          ? ENDPOINTS.accountingLinkedChartOfAccount()
          : ENDPOINTS.linkedChartOfAccount()
      });

      if (!isEmpty(data)) {
        const obj: Record<string, any> = {};
        Object.keys(data).forEach(i => {
          obj[i] = data[i].map((d: any) => {
            return {
              ...d,
              code: d.subCategory,
              name: d.subCategoryName,
              chartOfAccount: d.chartOfAccountId
                ? {
                    id: d.chartOfAccountId,
                    displayName: d.chartOfAccountDisplayName,
                    currencyCode: [
                      LinkedAccountCode.SalesAssetUSD,
                      LinkedAccountCode.PurchasesAssetUSD
                    ].includes(d.subCategory)
                      ? 'USD'
                      : 'VND'
                  }
                : undefined
            };
          });
        });

        return obj;
      }

      return null;
    }
  });
};

export const useModifyLinkedChartOfAccount = () => {
  return useMutationEnhancer<any, any>({
    mutationKeys: [
      [QUERY_KEYS.linkedChartOfAccount],
      [QUERY_KEYS.accountingLinkedChartOfAccount]
    ],
    mutationFn: data =>
      APIManager.request({
        url: ENDPOINTS.accountingLinkedChartOfAccount(),
        method: 'POST',
        body: data,
        showToast: true
      })
  });
};

export const useFetchChartOfAccountList = (arr?: number[]) => {
  return useQueryEnhancer<any | any>({
    queryKey: [QUERY_KEYS.accountingChartOfAccount],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.accountingChartOfAccount(),
        body: {
          includeFields: ['accountClassification', 'currency', 'taxType'],
          size: PAGE_SIZE_DEFAULT_MAX
        }
      });

      if (!arr) return res.data?.data;

      const arrSorted = sortSelected(res.data?.data, arr);

      return arrSorted;
    }
  });
};
