import trans from 'translation';
import { KColors } from 'uikit';
import { UIUtils } from 'utils';

interface IWrapWithAlertParams {
  msg: string;
  cb?: (d?: any) => void;
}

export const wrapWithAlert = (mParams: IWrapWithAlertParams) => (d?: any) => {
  const { msg, cb } = mParams;

  UIUtils.alert.open({
    message: msg,
    buttons: [
      {
        title: trans('ok'),
        kind: 'danger',
        color: KColors.danger.normal,
        onPress: () => cb?.(d)
      },
      {
        title: trans('cancel')
      }
    ]
  });
};

export const wrapWithAlertCb = (mParams: IWrapWithAlertParams) => {
  return wrapWithAlert(mParams)();
};
