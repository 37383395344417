/* eslint-disable max-lines */
import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import React from 'react';
import { LoaderFunctionArgs, Navigate } from 'react-router-dom';

import { ENDPOINTS, GroupOceanFreight } from '@constants';
import APIManager from '@services';
import Consol from 'pages/OceanFreight/Consol';
import ShipmentNoConsol from 'pages/OceanFreight/ShipmentNoConsol';

import { IAdminRoute } from './helpers';

const ConsolLayout = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Consol/DetailsLayout'), 200)
);
const ConsolDetails = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Consol/Details'), 200)
);
const ConsolRouting = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Consol/Routing'), 200)
);
const ConsolTransport = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Consol/Transport'), 200)
);
const ConsolWip = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Consol/Wip'), 200)
);
const ConsolContainer = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Consol/Container'), 200)
);
const ConsolContainerDetails = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Consol/Container/Details'), 200)
);
const ConsolAlertNote = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Consol/AlertNote'), 200)
);
const ConsolDocument = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Consol/EmailEdoc'), 200)
);
const ConsolLog = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Consol/LogWrapper'), 200)
);
const ConsolAccounting = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Consol/Accounting'), 200)
);
const ConsolApInvoiceDetails = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Consol/Accounting/AP/Detail'), 200)
);
const ConsoleArInvoiceDetails = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Consol/Accounting/AR/Detail'), 200)
);

const Shipment = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Shipment'), 200)
);
const ShipmentLayout = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Shipment/DetailsLayout'), 200)
);
const ShipmentDetails = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Shipment/Details'), 200)
);
const ShipmentRouting = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Shipment/Routing'), 200)
);
const ShipmentService = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Shipment/Service'), 200)
);
const ShipmentContainer = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Shipment/Container'), 200)
);
const ShipmentContainerDetails = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Shipment/Container/Details'), 200)
);
const ShipmentAlertNote = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Shipment/AlertNote'), 200)
);
const ShipmentDocument = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Shipment/EmailEdoc'), 200)
);
const ShipmentLog = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Shipment/LogWrapper'), 200)
);
const ShipmentBilling = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Shipment/_Billing'), 200)
);
const ShipmentArInvoiceDetails = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Shipment/_Billing/Ar/Details'), 200)
);
const ShipmentApInvoiceDetails = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Shipment/_Billing/Ap/Details'), 200)
);
const ShipmentWip = loadable(() =>
  pMinDelay(import('pages/OceanFreight/Shipment/Wip'), 200)
);

const ShipmentNoConsolWip = loadable(() =>
  pMinDelay(import('pages/OceanFreight/ShipmentNoConsol/Wip'), 200)
);

const ShipmentNoConsolLayout = loadable(() =>
  pMinDelay(import('pages/OceanFreight/ShipmentNoConsol/DetailsLayout'), 200)
);
const ShipmentNoConsolDetails = loadable(() =>
  pMinDelay(import('pages/OceanFreight/ShipmentNoConsol/Details'), 200)
);
const ShipmentNoConsolDocument = loadable(() =>
  pMinDelay(import('pages/OceanFreight/ShipmentNoConsol/Edoc'), 200)
);
const ShipmentNoConsolAlertNote = loadable(() =>
  pMinDelay(import('pages/OceanFreight/ShipmentNoConsol/AlertNote'), 200)
);
const ShipmentNoConsolLog = loadable(() =>
  pMinDelay(import('pages/OceanFreight/ShipmentNoConsol/LogWrapper'), 200)
);

const oceanFreightRoutes: IAdminRoute = {
  name: 'Ocean Freight',
  path: 'ocean-freight',
  menu: [
    GroupOceanFreight.OCEAN_CONSOL_DETAIL_LIST,
    GroupOceanFreight.OCEAN_SHIPMENT_DETAIL_LIST
  ],
  icon: 'sailing',
  children: [
    {
      parentKey: 'ocean-freight',
      name: 'Consolidation',
      menu: [GroupOceanFreight.OCEAN_CONSOL_DETAIL_LIST],
      path: 'consolidation',
      icon: 'view_in_ar',
      children: [
        {
          index: true,
          element: <Consol />
        },
        {
          path: 'new',
          element: <ConsolLayout key="new" />,
          children: [
            {
              index: true,
              element: <ConsolDetails key="new" />
            }
          ]
        },
        {
          path: ':id',
          element: <ConsolLayout key=":id" />,
          children: [
            {
              index: true,
              element: <ConsolDetails key=":id" />
            },
            {
              path: 'routing',
              element: <ConsolRouting />
            },
            {
              path: 'transport',
              element: <ConsolTransport />
            },
            {
              path: 'shipment',
              children: [
                {
                  index: true,
                  element: <Shipment />
                },
                {
                  path: 'new',
                  element: <ShipmentLayout key="new" />,
                  children: [
                    {
                      index: true,
                      element: <ShipmentDetails key="new" />
                    }
                  ]
                },
                {
                  path: ':shipmentId',
                  element: <ShipmentLayout key=":shipmentId" />,
                  children: [
                    {
                      index: true,
                      element: <ShipmentDetails key=":shipmentId" />
                    },
                    {
                      path: 'routing',
                      element: <ShipmentRouting />
                    },
                    {
                      path: 'containers-packing',
                      children: [
                        {
                          index: true,
                          element: <ShipmentContainer />
                        },
                        {
                          path: 'new',
                          element: <ShipmentContainerDetails key="new" />
                        },
                        {
                          path: ':containerId',
                          element: (
                            <ShipmentContainerDetails key=":containerId" />
                          )
                        }
                      ]
                    },
                    {
                      path: 'service',
                      element: <ShipmentService />
                    },
                    {
                      path: 'wip',
                      element: <ShipmentWip />
                    },
                    {
                      path: 'billing',
                      children: [
                        {
                          index: true,
                          element: <ShipmentBilling />
                        },
                        {
                          path: 'ar',
                          children: [
                            {
                              index: true,
                              element: <Navigate to="../" />
                            },
                            {
                              path: 'new',
                              element: <ShipmentArInvoiceDetails key="new" />,
                              code: [
                                GroupOceanFreight.OCEAN_SHIPMENT_AR_INVOICE_DETAIL
                              ]
                            },
                            {
                              path: ':arInvoiceId',
                              element: (
                                <ShipmentArInvoiceDetails key=":arInvoiceId" />
                              ),
                              code: [
                                GroupOceanFreight.OCEAN_SHIPMENT_AR_INVOICE_DETAIL
                              ]
                            }
                          ]
                        },
                        {
                          path: 'ap',
                          children: [
                            {
                              index: true,
                              element: <Navigate to="../" />
                            },
                            {
                              path: 'new',
                              element: <ShipmentApInvoiceDetails key="new" />,
                              code: [
                                GroupOceanFreight.OCEAN_SHIPMENT_AP_INVOICE_DETAIL
                              ]
                            },
                            {
                              path: ':apInvoiceId',
                              element: (
                                <ShipmentApInvoiceDetails key=":apInvoiceId" />
                              ),
                              code: [
                                GroupOceanFreight.OCEAN_SHIPMENT_AP_INVOICE_DETAIL
                              ]
                            }
                          ]
                        }
                      ]
                      // element: <ShipmentBilling />
                    },
                    {
                      path: 'alert-note',
                      element: <ShipmentAlertNote />
                    },
                    {
                      path: 'document',
                      element: <ShipmentDocument />
                    },
                    {
                      path: 'history',
                      code: [GroupOceanFreight.OCEAN_SHIPMENT_LOG_LIST],
                      element: <ShipmentLog />
                    }
                  ]
                }
              ]
            },
            {
              path: 'containers-packing',
              children: [
                {
                  index: true,
                  element: <ConsolContainer />
                },
                {
                  path: 'new',
                  element: <ConsolContainerDetails key="new" />
                },
                {
                  path: ':containerId',
                  element: <ConsolContainerDetails key=":containerId" />
                }
              ]
            },
            {
              path: 'wip',
              element: <ConsolWip />
            },
            {
              path: 'accounting',
              children: [
                {
                  index: true,
                  element: <ConsolAccounting />
                },
                {
                  path: 'ap',
                  children: [
                    {
                      index: true,
                      element: <Navigate to="../" />
                    },
                    {
                      path: 'new',
                      element: <ConsolApInvoiceDetails key="new" />,
                      code: [GroupOceanFreight.OCEAN_CONSOL_AP_INVOICE_DETAIL]
                    },
                    {
                      path: ':apInvoiceId',
                      element: <ConsolApInvoiceDetails key=":apInvoiceId" />,
                      code: [GroupOceanFreight.OCEAN_CONSOL_AP_INVOICE_DETAIL]
                    }
                  ]
                },
                {
                  path: 'ar',
                  children: [
                    {
                      index: true,
                      element: <Navigate to="../" />
                    },
                    {
                      path: 'new',
                      element: <ConsoleArInvoiceDetails key="new" />,
                      code: [GroupOceanFreight.OCEAN_CONSOL_AR_INVOICE_DETAIL]
                    },
                    {
                      path: ':arInvoiceId',
                      element: <ConsoleArInvoiceDetails key=":arInvoiceId" />,
                      code: [GroupOceanFreight.OCEAN_CONSOL_AR_INVOICE_DETAIL]
                    }
                  ]
                }
              ]
            },
            {
              path: 'alert-note',
              element: <ConsolAlertNote />
            },
            {
              path: 'document',
              element: <ConsolDocument />
            },
            {
              path: 'history',
              code: [GroupOceanFreight.OCEAN_CONSOL_LOG_LIST],
              element: <ConsolLog />
            }
          ]
        }
      ]
    },
    {
      parentKey: 'ocean-freight',
      name: 'Shipment',
      menu: [GroupOceanFreight.OCEAN_SHIPMENT_DETAIL_LIST],
      path: 'shipment',
      icon: 'list_alt',
      children: [
        {
          index: true,
          element: <ShipmentNoConsol />,
          menu: [GroupOceanFreight.OCEAN_SHIPMENT_DETAIL_LIST]
        },
        {
          path: ':shipmentId',
          element: <ShipmentNoConsolLayout key=":shipmentId" />,
          loader: async ({ params, request }: LoaderFunctionArgs) => {
            const { shipmentId } = params || {};

            const res = await APIManager.request({
              method: 'GET',
              url: ENDPOINTS.shipment(':id/consol-id', {
                id: shipmentId
              })
            });

            if (!!shipmentId && !!res.data?.consolId) {
              const url = new URL(request.url);
              const newPath =
                url.pathname.replace(
                  `/shipment/${shipmentId}`,
                  `/consolidation/${res.data.consolId}/shipment/${shipmentId}`
                ) || '';

              throw new Response('', {
                status: 302,
                headers: {
                  Location: newPath
                }
              });
            }

            return res.data || {};
          },
          children: [
            {
              index: true,
              element: <ShipmentNoConsolDetails />
            },
            {
              path: 'routing',
              element: <ShipmentRouting />
            },
            {
              path: 'document',
              element: <ShipmentNoConsolDocument />
            },
            {
              path: 'alert-note',
              element: <ShipmentNoConsolAlertNote />
            },
            {
              path: 'history',
              code: [GroupOceanFreight.OCEAN_SHIPMENT_LOG_LIST],
              element: <ShipmentNoConsolLog />
            },
            {
              path: 'wip',
              element: <ShipmentNoConsolWip />
            },
            {
              path: 'service',
              element: <ShipmentService />
            },
            {
              path: 'containers-packing',
              children: [
                {
                  index: true,
                  element: <ShipmentContainer />
                },
                {
                  path: 'new',
                  element: <ShipmentContainerDetails key="new" />
                },
                {
                  path: ':containerId',
                  element: <ShipmentContainerDetails key=":containerId" />
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default oceanFreightRoutes;
