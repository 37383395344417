import { IUseAgTableProps, IUseAgTableResult } from '@hooks-dto';
import {
  CellValueChangedEvent,
  RowDataUpdatedEvent,
  SelectionChangedEvent,
  DragStoppedEvent,
  GridReadyEvent,
  IRowNode,
  ColDef
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { cloneDeep, debounce, isEmpty, set } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

import { exportDataToExcel } from 'utils/excel';
import StorageEnhance, { STORAGE_KEYS } from 'utils/storage';

const useAgTable = <T = any>({
  name,
  data,
  emptyData,
  colDefs,

  isSuccess,

  onRefresh,

  onAdd,
  onAddParams,

  onEndEditing,

  onExportParams,

  onCellValueChanged,
  onSelectionChanged,
  onCheckParams,

  onDelete,
  onDeleteParams,

  customFilterColumns = [],

  defaultSelected = [],

  defIsCustomFilter = false
}: IUseAgTableProps<T>): IUseAgTableResult<T> => {
  const gridRef = useRef<AgGridReact<T>>(null);

  const [isGridReady, setIsGridReady] = useState<boolean>(false);

  const [rowData, setRowData] = useState<T[]>(data ?? []);
  const [selectedData, setSelectedData] = useState<T[]>([]);
  const [hasSelectedData, setHasSelectedData] = useState<boolean>(false);

  const [keyword, setKeyword] = useState<string>('');
  // const [isCustomFilter, setIsCustomFilter] = useState<boolean>(defIsCustomFilter);

  const isMounted = useRef(false);

  const quickFilterColumns = (colDefs ?? [])
    .filter(col => col.field && !customFilterColumns.includes(col.field))
    .map(col => col.field);

  useEffect(() => {
    if (
      Array.isArray(data) &&
      JSON.stringify(rowData) !== JSON.stringify(data)
    ) {
      setRowData(data as T[]);
      if (onCheckParams?.field && !isEmpty(data)) {
        setHasSelectedData(
          (data as any[]).some(i => !!i?.[onCheckParams.field])
        );
      }
    } else if (!isMounted.current && isSuccess && isEmpty(data) && emptyData) {
      isMounted.current = true;
      setRowData(emptyData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data), rowData, isSuccess, onCheckParams?.field]);

  // Custom OR-based filter function for specific columns
  const passesCustomFilter = useCallback(
    (node: IRowNode<T>) => {
      if (!keyword) return false;

      const terms = keyword.toLowerCase().split(' ');

      return terms.some(term =>
        customFilterColumns.some(col => {
          const cellValue = ((node?.data as any)?.[col] || '')
            .toString()
            .toLowerCase();
          return cellValue.includes(term);
        })
      );
    },
    [customFilterColumns, keyword]
  );

  // Normal filter function restricted to quickFilterColumns
  const passesQuickFilterOnColumns = useCallback(
    (node: IRowNode<T>) => {
      if (!keyword) return false;

      const quickFilterText = keyword.toLowerCase();

      return quickFilterColumns.some(col => {
        const cellValue = ((node?.data as any)?.[col] || '')
          .toString()
          .toLowerCase();
        return cellValue.includes(quickFilterText);
      });
    },
    [keyword, quickFilterColumns]
  );

  // Combined filter logic: Show row if it passes either quick filter or custom filter in custom mode
  const doesExternalFilterPass = useCallback(
    (node: IRowNode<T>) => {
      if (!defIsCustomFilter || !keyword) return true; // In normal filter mode, always return true

      const passesQuickFilter = passesQuickFilterOnColumns(node);
      const passesCustomOrFilter = passesCustomFilter(node);
      return passesQuickFilter || passesCustomOrFilter; // OR condition: Show row if it passes either filter
    },
    [defIsCustomFilter, keyword, passesCustomFilter, passesQuickFilterOnColumns]
  );

  useEffect(() => {
    if (defIsCustomFilter) {
      gridRef.current?.api?.onFilterChanged(); // Apply combined custom and quick filter logic
    } else {
      gridRef.current?.api?.setGridOption('quickFilterText', keyword);
    }
  }, [defIsCustomFilter, keyword]);

  useEffect(() => {
    if (!isEmpty(defaultSelected) && isGridReady) {
      const objValueSelected = Object.fromEntries(
        defaultSelected.map(x => [x, true])
      );
      gridRef.current?.api?.forEachNode((rowNode: IRowNode) => {
        if (objValueSelected[rowNode.data.id]) {
          rowNode.setSelected(true);
        }
      });
    }
  }, [defaultSelected, isGridReady, rowData]);

  const onGetData = useCallback(() => {
    const rows: T[] = [];
    gridRef.current?.api?.forEachNode(rowNode => rows.push(rowNode.data as T));
    return rows;
  }, []);

  const onGetSelectedData = useCallback(() => {
    return gridRef.current?.api?.getSelectedRows() ?? [];
  }, []);

  const debounceSetHasSelectedData = debounce((d: boolean) => {
    setHasSelectedData(d);
  }, 200);

  const _onSelectionChanged = useCallback(
    (event: SelectionChangedEvent<T>) => {
      setSelectedData(event.api.getSelectedRows());
      debounceSetHasSelectedData(event.api.getSelectedRows().length > 0);

      onSelectionChanged?.(event);
    },
    [debounceSetHasSelectedData, onSelectionChanged]
  );

  const onAddLastRow = useCallback(
    (initialRowData?: T[]) => {
      onAdd?.() ??
        gridRef.current?.api.applyTransaction({
          add: initialRowData ?? [
            { action: 'addLastRow', ...onAddParams?.initialValue } as T
          ]
        });
    },
    [onAdd, onAddParams?.initialValue]
  );

  const onRemove = useCallback(() => {
    onDelete?.() ??
      gridRef.current?.api.applyTransaction({
        remove: selectedData
      });
  }, [onDelete, selectedData]);

  const onEndEditingRows = useCallback(
    (event: RowDataUpdatedEvent<T>) => {
      const rows: T[] = [];
      event.api.forEachNode(rowNode => rows.push(rowNode.data as T));
      if (JSON.stringify(rowData) != JSON.stringify(rows)) {
        onEndEditing?.(rows);
      }
    },
    [onEndEditing, rowData]
  );

  const _onCellValueChanged = useCallback(
    (event: CellValueChangedEvent<T>) => {
      if (
        onCheckParams?.field &&
        event.column.getColId() === onCheckParams.field
      ) {
        const rows: T[] = [];
        event.api.forEachNode(rowNode => rows.push(rowNode.data as T));

        debounceSetHasSelectedData(
          rows.some((i: any) => !!i?.[onCheckParams.field])
        );
      }

      onCellValueChanged?.(event);
    },
    [onCheckParams?.field, onCellValueChanged, debounceSetHasSelectedData]
  );

  const onDragStopped = useCallback(
    (event: DragStoppedEvent<any>) => {
      const agGridConfigs = StorageEnhance.get(STORAGE_KEYS.agGrid) ?? {};
      const savedConfigs = cloneDeep(agGridConfigs);
      set(savedConfigs, `columnStates.${name}`, event.api.getColumnState());
      StorageEnhance.set(STORAGE_KEYS.agGrid, savedConfigs);
    },
    [name]
  );

  const onGridReady = useCallback(
    (event: GridReadyEvent<any>) => {
      setIsGridReady(true);
      const agGridConfigs = StorageEnhance.get(STORAGE_KEYS.agGrid) ?? {};
      const savedState = agGridConfigs?.columnStates?.[name];
      if (savedState) {
        event.api.applyColumnState({ state: savedState, applyOrder: true });
      }
    },
    [name]
  );

  const onRowDataUpdated = useCallback(
    (event: RowDataUpdatedEvent<T>) => {
      const agGridConfigs = StorageEnhance.get(STORAGE_KEYS.agGrid) ?? {};
      const savedState = agGridConfigs?.columnStates?.[name];
      if (savedState && isGridReady) {
        event.api.applyColumnState({ state: savedState, applyOrder: true });
      }
    },
    [isGridReady, name]
  );

  const onExport = useCallback(
    async (fileName?: string) => {
      if (gridRef.current?.api) {
        const exportColDefs: ColDef<any>[] =
          gridRef.current.api
            .getColumnDefs()
            ?.filter(
              (i: ColDef<any>) =>
                !i.hide &&
                (!i.colId ||
                  !['isChecked', 'checkbox', 'action'].includes(i.colId))
            ) ?? [];

        const exportData =
          gridRef.current.api
            .getDataAsCsv({
              suppressQuotes: true,
              columnSeparator: '__',
              columnKeys: exportColDefs.map(i => i.colId as string)
            })
            ?.split('\r\n')
            .map(i => {
              return i.split('__');
            }) ?? [];

        const exportParams = {
          ...onExportParams,
          colDefs: exportColDefs
        };

        await exportDataToExcel(
          fileName || onExportParams?.name || 'report.xlsx',
          exportData,
          exportParams
        );
      }
    },
    [onExportParams]
  );

  const isExternalFilterPresent = useCallback(() => {
    return defIsCustomFilter;
  }, [defIsCustomFilter]);

  return {
    gridRef,

    name,

    rowData,
    columnDefs: colDefs,
    selectedData,
    hasSelectedData,
    keyword,

    onAddParams,
    onDeleteParams,
    onExportParams,

    onAdd: onAddLastRow,
    onExport,
    onSearch: setKeyword,
    onGetData,
    onGetSelectedData,
    onRefresh,
    init: onRefresh,
    onDelete: onRemove,

    onSelectionChanged: _onSelectionChanged,
    onRowDataUpdated,
    onRowEditingStopped: onEndEditingRows,
    onCellValueChanged: _onCellValueChanged,
    onDragStopped,
    onGridReady,

    // Custom Filter
    isExternalFilterPresent,
    doesExternalFilterPass
  };
};

export default useAgTable;
