import { IBranch } from '@dto';
import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import { ENDPOINTS } from '@constants';
import { BottomActions } from 'components';
import { useResolverForm } from 'hooks';
import {
  useCUDBranchSetting,
  useFetchBranchSetting
} from 'hooks/ssLibrary/setting';
import trans from 'translation';
import { KContainer, KForm, KGrid, KInput } from 'uikit';
import { RequestUtils, ValidationUtils } from 'utils';

import { IFormData } from '../helpers';

type IDefaultOption = Pick<
  IFormData,
  | 'id'
  | 'sendingAgent'
  | 'receivingAgent'
  | 'arInvoiceLeader'
  | 'apInvoiceLeader'
  | 'serviceLeader'
>;

const schema = yup.object().shape({
  sendingAgent: ValidationUtils.requiredAny(),
  receivingAgent: ValidationUtils.requiredAny(),
  arInvoiceLeader: ValidationUtils.requiredAny(),
  apInvoiceLeader: ValidationUtils.requiredAny(),
  serviceLeader: ValidationUtils.requiredAny()
});

interface IProps {
  branch: IBranch;
}

export default function DefaultOption({ branch }: IProps) {
  const { data: setting } = useFetchBranchSetting(branch?.id);
  const {
    updateDefaultOptionMutation: { mutate: updateDefaultOption, isLoading }
  } = useCUDBranchSetting();

  const methods = useResolverForm<IDefaultOption>({
    schema,
    configs: {
      values: {
        id: setting?.id,
        sendingAgent: setting?.sendingAgent,
        receivingAgent: setting?.receivingAgent,
        arInvoiceLeader: setting?.arInvoiceLeader,
        apInvoiceLeader: setting?.apInvoiceLeader,
        serviceLeader: setting?.serviceLeader
      }
    }
  });

  const onFormValid = useCallback(
    (data: IDefaultOption) => {
      const mParams = {
        ...RequestUtils.normalizeData({
          data,
          idFields: [
            'sendingAgent',
            'receivingAgent',
            'arInvoiceLeader',
            'apInvoiceLeader',
            'serviceLeader'
          ]
        })
      };

      updateDefaultOption(mParams);
    },
    [updateDefaultOption]
  );
  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KContainer.Card
        header={{
          icon: 'ErrorOutlineOutlined',
          title: trans('tab.default_agent_setting')
        }}
      >
        <KGrid.Container>
          <KGrid.Item xs={6}>
            <Controller
              name="sendingAgent"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.Autocomplete
                    {...field}
                    apiURL={ENDPOINTS.client()}
                    label={trans('sending_agent_export')}
                    apiParams={{
                      branchId: branch?.id
                    }}
                    inputProps={{
                      required: true,
                      message: error?.message
                    }}
                  />
                );
              }}
            />
          </KGrid.Item>
          <KGrid.Item xs={6}>
            <Controller
              name="receivingAgent"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.Autocomplete
                    {...field}
                    apiURL={ENDPOINTS.client()}
                    label={trans('receving_agent_import')}
                    inputProps={{
                      required: true,
                      message: error?.message
                    }}
                    apiParams={{
                      branchId: branch?.id
                    }}
                  />
                );
              }}
            />
          </KGrid.Item>
        </KGrid.Container>
      </KContainer.Card>
      <KContainer.Card
        header={{
          icon: 'ErrorOutlineOutlined',
          title: trans('tab.notification_setting')
        }}
        marginT={'0.75rem'}
      >
        <KGrid.Container>
          <KGrid.Item xs={6}>
            <Controller
              name="serviceLeader"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.Autocomplete
                    {...field}
                    label={trans(
                      'new_service_created_shipment_send_notification_to'
                    )}
                    apiURL={ENDPOINTS.staff()}
                    getOptionLabel={(o: any) => o?.email ?? ''}
                    apiParams={{
                      branchId: branch?.id
                    }}
                    inputProps={{
                      required: true,
                      message: error?.message
                    }}
                  />
                );
              }}
            />
          </KGrid.Item>
          <KGrid.Item xs={6}></KGrid.Item>
          <KGrid.Item xs={6}>
            <Controller
              name="arInvoiceLeader"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.Autocomplete
                    {...field}
                    label={trans(
                      'ordinary_ar_invoice_invoice_request_send_notification_to'
                    )}
                    apiURL={ENDPOINTS.staff()}
                    getOptionLabel={(o: any) => o?.email ?? ''}
                    apiParams={{
                      branchId: branch?.id
                    }}
                    inputProps={{
                      required: true,
                      message: error?.message
                    }}
                  />
                );
              }}
            />
          </KGrid.Item>
          <KGrid.Item xs={6}>
            <Controller
              name="apInvoiceLeader"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.Autocomplete
                    {...field}
                    label={trans(
                      'ordinary_ap_invoice_invoice_payment_request_send_notification_to'
                    )}
                    apiURL={ENDPOINTS.staff()}
                    apiParams={{
                      branchId: branch?.id
                    }}
                    getOptionLabel={(o: any) => o?.email ?? ''}
                    inputProps={{
                      required: true,
                      message: error?.message
                    }}
                  />
                );
              }}
            />
          </KGrid.Item>
        </KGrid.Container>
      </KContainer.Card>
      <BottomActions
        btnProps={[
          {
            isLoading
          }
        ]}
      />
    </KForm>
  );
}
