import { IModifyBranchSetting } from '@request-dto';
import { isEmpty } from 'lodash';

import { ENDPOINTS, QUERY_KEYS, WebTable } from '@constants';
import APIManager from '@services';
import { useUser } from 'hooks/auth';

import { useCUDMutationEnhancer, useQueryEnhancer } from '../../core';

export const useFetchBranchSetting = (branchId?: number | string) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.branchSetting, branchId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.branchSetting(),
        body: {
          branchId,
          includeFields: ['autoExchangeTime', 'autoExchangeErrorNotify']
        }
      });

      if (!isEmpty(res.data)) {
        return {
          ...res.data,
          currency: res.data.primaryCurrency
        };
      }

      return res.data;
    },
    enabled: !!branchId
  });
};

export const useFetchSettings = () => {
  const { branch } = useUser();

  return useFetchBranchSetting(branch?.id);
};

export const useCUDBranchSetting = () => {
  return useCUDMutationEnhancer<IModifyBranchSetting>({
    endPoint: ENDPOINTS.branchSetting()
  });
};

export const useCUDTermsOfCondition = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.termsOfConditions(),
    webTable: WebTable.TERMS_OF_CONDITION
  });
};
