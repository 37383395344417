import { IChargeCode } from '@invoice';
import { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community';
import React from 'react';

import { CustomButtonComponent } from 'components/SheetData/helper';
import trans from 'translation';
import { KColors, KImage, KSwitch } from 'uikit';
import AgUtils from 'utils/ag';

export const columnDefs = ({
  handleDeleteChargeCode
}: {
  handleDeleteChargeCode: (rowDeleted: IChargeCode[]) => void;
}): ColDef<IChargeCode>[] | ColGroupDef<IChargeCode>[] => {
  return [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      maxWidth: 50,
      pinned: 'left',
      filter: false,
      sortable: false,
      lockVisible: true,
      colId: 'checkbox'
    },
    {
      colId: 'action',
      pinned: 'left',
      headerName: trans('action'),
      cellRenderer: CustomButtonComponent,
      cellRendererParams: {
        onDelete: ({ data }: ICellRendererParams) => {
          handleDeleteChargeCode([data]);
        }
      },
      maxWidth: 98,
      minWidth: 98,
      suppressMovable: true,
      lockPinned: true,
      filter: false,
      sortable: false
    },
    {
      headerName: trans('charge_code'),
      field: 'code'
    },
    {
      headerName: trans('description'),
      field: 'description',
      minWidth: 180
    },
    {
      headerName: trans('cont_mode_unit'),
      field: 'conModeUnit',
      ...AgUtils.options.withMore({
        onNormalizeData: v => v.split(',')
      })
    },
    {
      headerName: trans('calculator_type'),
      field: 'calculatorType',
      ...AgUtils.options.chip()
    },
    {
      headerName: trans('base_unit_price'),
      field: 'baseValue',
      type: 'number'
    },
    {
      headerName: trans('min_selling_unit_price'),
      field: 'minSelling',
      type: 'number'
    },
    {
      headerName: trans('markup_type'),
      field: 'markupType',
      ...AgUtils.options.chip()
    },
    {
      headerName: trans('markup_level_1'),
      field: 'markupLevel1',
      type: 'number'
    },
    {
      headerName: trans('markup_level_2'),
      field: 'markupLevel2',
      type: 'number'
    },
    {
      headerName: trans('markup_level_3'),
      field: 'markupLevel3',
      type: 'number'
    },
    {
      headerName: trans('is_system'),
      field: 'isSystem',
      cellRenderer: ({ value }: ICellRendererParams<IChargeCode>) => {
        return (
          <KImage.MuiIcon
            icon="CheckCircleOutlineOutlined"
            color={value ? KColors.primary.normal : KColors.palette.gray.w50}
          />
        );
      }
    },
    {
      headerName: trans('status'),
      field: 'status',
      cellRenderer: ({ value }: ICellRendererParams<IChargeCode>) => {
        return <KSwitch id="status" checked={value} />;
      }
    }
  ];
};
