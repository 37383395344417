import React, { memo } from 'react';
import trans from 'translation';

import Log from 'containers/Log';
import { KBreadcrumbs } from 'uikit';

const SystemHistory = () => {
  return (
    <>
      <KBreadcrumbs
        hasBackIcon={false}
        breadcrumbs={[
          trans('routes.system_settings'),
          trans('routes.system_history')
        ]}
      />

      <Log noHeader margin="0.75rem" tableMaxHeight="68vh" />
    </>
  );
};

export default memo(SystemHistory);
