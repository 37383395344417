import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import React from 'react';

import { AdviceType, GroupAccounting } from '@constants';
import AccountingLayout from 'pages/Accounting/DetailsLayout';

import { IAdminRoute } from './helpers';

const Dashboard = loadable(() =>
  pMinDelay(import('pages/Accounting/DashBoard'), 200)
);

const SalesLayout = loadable(() =>
  pMinDelay(import('pages/Accounting/Sales/DetailsLayout'), 200)
);

const SalesArInvoice = loadable(() =>
  pMinDelay(import('pages/Accounting/Sales/ArInvoice'), 200)
);

const SalesHistory = loadable(() =>
  pMinDelay(import('pages/Accounting/Sales/LogWrapper'), 200)
);

const PurchasesLayout = loadable(() =>
  pMinDelay(import('pages/Accounting/Purchases/DetailsLayout'), 200)
);

const PurchasesApInvoice = loadable(() =>
  pMinDelay(import('pages/Accounting/Purchases/ApInvoice'), 200)
);

const PurchasesHistory = loadable(() =>
  pMinDelay(import('pages/Accounting/Purchases/LogWrapper'), 200)
);

const StatementLayout = loadable(() =>
  pMinDelay(import('pages/Accounting/Statement/DetailsLayout'), 200)
);

const StatementOfAccount = loadable(() =>
  pMinDelay(import('pages/Accounting/Statement/StatementOfAccount'), 200)
);

const StatementOfAccountDetails = loadable(() =>
  pMinDelay(
    import('pages/Accounting/Statement/StatementOfAccount/Details'),
    200
  )
);

const StatementPayment = loadable(() =>
  pMinDelay(import('pages/Accounting/Statement/Payment'), 200)
);

const StatementPaymentDetails = loadable(() =>
  pMinDelay(import('pages/Accounting/Statement/Payment/Details'), 200)
);

const StatementInvoices = loadable(() =>
  pMinDelay(import('pages/Accounting/Statement/StatementInvoices'), 200)
);

const StatementInvoiceDetails = loadable(() =>
  pMinDelay(import('pages/Accounting/Statement/StatementInvoices/Details'), 200)
);

const StatementHistory = loadable(() =>
  pMinDelay(import('pages/Accounting/Statement/LogWrapper'), 200)
);

const MoreLayout = loadable(() =>
  pMinDelay(import('pages/Accounting/More/DetailsLayout'), 200)
);

const MoreChartOfAccount = loadable(() =>
  pMinDelay(import('pages/Accounting/More/ChartOfAccounts'), 200)
);

const MoreManageLinkedAccount = loadable(() =>
  pMinDelay(import('pages/Accounting/More/ManageLinkedAccount'), 200)
);

const MoreTaxCode = loadable(() =>
  pMinDelay(import('pages/Accounting/More/TaxCode'), 200)
);

const MoreHistory = loadable(() =>
  pMinDelay(import('pages/Accounting/More/LogWrapper'), 200)
);

const BankingLayout = loadable(() =>
  pMinDelay(import('pages/Accounting/Bankingg/DetailsLayout'), 200)
);

const BankingBankAccount = loadable(() =>
  pMinDelay(import('pages/Accounting/Bankingg/BankAccount'), 200)
);

const BankingBankTransaction = loadable(() =>
  pMinDelay(import('pages/Accounting/Bankingg/BankTransaction'), 200)
);

const BankingMoney = loadable(() =>
  pMinDelay(import('pages/Accounting/Bankingg/Money'), 200)
);

const BankingMoneyDetail = loadable(() =>
  pMinDelay(import('pages/Accounting/Bankingg/Money/Details'), 200)
);

const BankingHistory = loadable(() =>
  pMinDelay(import('pages/Accounting/Bankingg/LogWrapper'), 200)
);

const AccountingAdvice = loadable(() =>
  pMinDelay(import('pages/Accounting/Advice'), 200)
);

const AccountingAdviceDetails = loadable(() =>
  pMinDelay(import('pages/Accounting/Advice/Details'), 200)
);

const Report = loadable(() =>
  pMinDelay(import('pages/Accounting/Report'), 200)
);

const accountingRoutes: IAdminRoute = {
  name: 'Accounting',
  path: 'accounting',
  menu: [
    GroupAccounting.ACCOUNTING_DASHBOARD_DETAIL,
    GroupAccounting.ACCOUNTING_AR_INVOICE_LIST,
    GroupAccounting.ACCOUNTING_RECEIVABLE_ADVICE_LIST,
    GroupAccounting.ACCOUNTING_AP_INVOICE_LIST,
    GroupAccounting.ACCOUNTING_PAYMENT_ADVICE_LIST,
    GroupAccounting.ACCOUNTING_STATEMENT_OF_ACCOUNT_LIST,
    GroupAccounting.ACCOUNTING_STATEMENT_PAYMENT_LIST,
    GroupAccounting.ACCOUNTING_STATEMENT_INVOICE_LIST,
    GroupAccounting.ACCOUNTING_BANK_ACCOUNT_LIST,
    GroupAccounting.ACCOUNTING_BANK_TRANSACTION_LIST,
    GroupAccounting.ACCOUNTING_RECEIVE_SPEND_MONEY_LIST
  ],
  element: <AccountingLayout />,
  icon: 'calculate',
  noChildren: true,
  children: [
    {
      parentKey: 'accounting',
      name: 'Dashboard',
      path: 'dashboard',
      element: <Dashboard />
    },
    {
      parentKey: 'accounting',
      name: 'Sales',
      path: 'sales',
      element: <SalesLayout />,
      children: [
        {
          index: true,
          element: <SalesArInvoice />
        },
        {
          path: 'receivable-advice',
          children: [
            {
              index: true,
              element: (
                <AccountingAdvice
                  key="receivableAdvice"
                  adviceType={AdviceType.Receivable}
                />
              )
            },
            {
              path: ':receivableAdviceId',
              element: (
                <AccountingAdviceDetails
                  key=":receivableAdviceDetails"
                  adviceType={AdviceType.Receivable}
                />
              )
            },
            {
              path: 'new',
              element: (
                <AccountingAdviceDetails
                  key="newReceivableAdviceDetails"
                  adviceType={AdviceType.Receivable}
                />
              )
            }
          ]
        },
        // {
        //   path: 'ar-client-summary',
        //   code: [GroupAccounting.ACCOUNTING_AR_INVOICE_SUMMARY],
        //   element: <ArClientSummary />
        // }
        {
          path: 'history',
          code: [GroupAccounting.ACCOUNTING_SALES_LOG_LIST],
          element: <SalesHistory />
        }
      ]
    },
    {
      parentKey: 'accounting',
      name: 'Purchases',
      path: 'purchases',
      element: <PurchasesLayout />,
      children: [
        {
          index: true,
          element: <PurchasesApInvoice />
        },
        {
          path: 'payment-advice',
          children: [
            {
              index: true,
              element: (
                <AccountingAdvice
                  key="paymentAdvice"
                  adviceType={AdviceType.Payment}
                />
              )
            },
            {
              path: ':paymentAdviceId',
              element: (
                <AccountingAdviceDetails
                  key="paymentAdviceDetails"
                  adviceType={AdviceType.Payment}
                />
              )
            },
            {
              path: 'new',
              element: (
                <AccountingAdviceDetails
                  key="newPaymentAdviceDetails"
                  adviceType={AdviceType.Payment}
                />
              )
            }
          ]
        },
        {
          path: 'history',
          code: [GroupAccounting.ACCOUNTING_PURCHASES_LOG_LIST],
          element: <PurchasesHistory />
        }
      ]
    },
    {
      parentKey: 'accounting',
      name: 'Statement',
      path: 'statement',
      element: <StatementLayout />,
      children: [
        {
          index: true,
          element: <StatementOfAccount />
        },
        {
          path: 'new',
          element: <StatementOfAccountDetails key="new" />
        },
        {
          path: ':statementOfAccountId',
          element: <StatementOfAccountDetails key=":statementOfAccountId" />
        },
        {
          path: 'statement-payment',
          children: [
            {
              index: true,
              element: <StatementPayment />
            },
            {
              path: 'new',
              element: <StatementPaymentDetails key="new" />
            },
            {
              path: ':paymentId',
              element: <StatementPaymentDetails key=":paymentId" />
            }
          ]
        },
        {
          path: 'statement-invoice',
          children: [
            {
              index: true,
              element: <StatementInvoices />
            },
            {
              path: ':statementInvoiceId',
              element: <StatementInvoiceDetails key=":statementInvoiceId" />
            }
          ]
        },
        {
          path: 'statement-history',
          code: [GroupAccounting.ACCOUNTING_STATEMENT_LOG_LIST],
          element: <StatementHistory />
        }
      ]
    },
    {
      parentKey: 'accounting',
      name: 'More',
      path: 'more',
      code: [
        GroupAccounting.ACCOUNTING_CHART_OF_ACCOUNT_LIST,
        GroupAccounting.ACCOUNTING_LINKED_ACCOUNT_LIST,
        GroupAccounting.ACCOUNTING_TAX_CODE_LIST
      ],
      element: <MoreLayout />,
      children: [
        // {
        //   index: true,
        //   element: <div>General Journals</div>
        // },
        {
          path: 'chart-of-accounts',
          code: [GroupAccounting.ACCOUNTING_CHART_OF_ACCOUNT_DETAIL],
          element: <MoreChartOfAccount />
        },
        {
          path: 'manage-linked-account',
          code: [GroupAccounting.ACCOUNTING_LINKED_ACCOUNT_LIST],
          element: <MoreManageLinkedAccount />
        },
        {
          path: 'tax-codes',
          code: [GroupAccounting.ACCOUNTING_TAX_CODE_LIST],
          element: <MoreTaxCode />
        },
        {
          path: 'history',
          code: [GroupAccounting.ACCOUNTING_MORE_LOG_LIST],
          element: <MoreHistory />
        }
      ]
    },
    {
      parentKey: 'accounting',
      name: 'Banking',
      path: 'banking',
      code: [
        GroupAccounting.ACCOUNTING_BANK_ACCOUNT_LIST,
        GroupAccounting.ACCOUNTING_BANK_TRANSACTION_LIST,
        GroupAccounting.ACCOUNTING_RECEIVE_SPEND_MONEY_LIST
      ],
      element: <BankingLayout />,
      children: [
        {
          index: true,
          code: [GroupAccounting.ACCOUNTING_BANK_ACCOUNT_LIST],
          element: <BankingBankAccount />
        },
        {
          path: 'bank-transaction',
          code: [GroupAccounting.ACCOUNTING_BANK_TRANSACTION_LIST],
          element: <BankingBankTransaction />
        },
        {
          path: 'money',
          code: [GroupAccounting.ACCOUNTING_RECEIVE_SPEND_MONEY_LIST],
          children: [
            {
              index: true,
              element: <BankingMoney />,
              code: [GroupAccounting.ACCOUNTING_RECEIVE_SPEND_MONEY_LIST]
            },
            {
              path: 'spend/:paymentSource/new',
              element: <BankingMoneyDetail key="spendNew" />,
              code: [GroupAccounting.ACCOUNTING_RECEIVE_SPEND_MONEY_DETAIL]
            },
            {
              path: 'spend/:paymentSource/:moneyId',
              element: <BankingMoneyDetail key=":spendMoneyId" />,
              code: [GroupAccounting.ACCOUNTING_RECEIVE_SPEND_MONEY_DETAIL]
            },
            {
              path: 'receive/:paymentSource/new',
              element: <BankingMoneyDetail key="receiveNew" />,
              code: [GroupAccounting.ACCOUNTING_RECEIVE_SPEND_MONEY_DETAIL]
            },
            {
              path: 'receive/:paymentSource/:moneyId',
              element: <BankingMoneyDetail key=":receiveMoneyId" />,
              code: [GroupAccounting.ACCOUNTING_RECEIVE_SPEND_MONEY_DETAIL]
            }
          ]
        },
        {
          path: 'history',
          code: [GroupAccounting.ACCOUNTING_BANKING_LOG_LIST],
          element: <BankingHistory />
        }
      ]
    },
    {
      parentKey: 'accounting',
      name: 'Report',
      path: 'report',
      element: <Report />
    }
  ]
};

export default accountingRoutes;
