import { IRelatedPartyParams } from '@request-dto';
import { useQueryClient } from '@tanstack/react-query';

import { QUERY_KEYS, WebTable, RelatedTab, ENDPOINTS } from '@constants';
import APIManager from '@services';

import { useCUDMutationEnhancer, useQueryEnhancer } from '../core';

const queryKey: any = {
  [RelatedTab.Regular]: QUERY_KEYS.relatedPartyList,
  [RelatedTab.Ecus]: QUERY_KEYS.ecusRelatedPartyList
};

export const useFetchRelatedPartyList = (clientId?: string | number) => {
  return useQueryEnhancer<any[] | undefined>({
    queryKey: [QUERY_KEYS.relatedPartyList, clientId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.restrictedClient(':id/related-client/client-type', {
          id: clientId
        })
      });

      return res.data;
    },
    enabled: !!clientId
  });
};

export const useFetchEcusRelatedPartyList = (clientId?: string | number) => {
  return useQueryEnhancer<any[] | undefined>({
    queryKey: [QUERY_KEYS.ecusRelatedPartyList, clientId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.restrictedClient(':id/related-client/ecus-client-type', {
          id: clientId
        })
      });

      return res.data;
    },
    enabled: !!clientId
  });
};

export const useCUDRelatedParty = (
  clientId?: string | number,
  tabName?: string
) => {
  const queryClient = useQueryClient();
  return useCUDMutationEnhancer<IRelatedPartyParams>({
    endPoint: ENDPOINTS.restrictedClient(':id/related-client', {
      id: clientId
    }),
    webTable: WebTable.CLIENT_RELATED_PARTIES,
    onSuccess: () => {
      if (tabName && queryKey?.[tabName]) {
        queryClient.invalidateQueries([queryKey[tabName]]);
      }
    }
  });
};
