import { IPaymentAdviceFormData, IReceivableAdviceFormData } from '@form-dto';
import React, { useCallback, useMemo } from 'react';
import {
  ControllerRenderProps,
  useFormContext,
  useWatch
} from 'react-hook-form';
import * as yup from 'yup';

import {
  AdviceType,
  ArApType,
  ENDPOINTS,
  TTypeAdvice,
  WebTable,
  AdviceStatus,
  SourceType,
  TableName
} from '@constants';
import APIManager from '@services';
import { wrapWithAlert } from 'hofs';
import { useMutationEnhancer } from 'hooks';
import trans from 'translation';
import { UIUtils, ValidationUtils } from 'utils';

import InvoiceList, {
  IProps as IInvoiceListProps
} from './TableDetail/InvoiceList';

export const checkLimitAmount = (value: any, balance: any) => {
  const isNegative = balance < 0;
  const { min, max } = isNegative
    ? {
        min: balance,
        max: -1
      }
    : {
        min: 1,
        max: balance
      };
  let val = Number(value);
  if (val > max) val = max;
  if (val < min) val = min;
  return val;
};

const payableSchema = yup.object().shape({
  accountPayable: ValidationUtils.requiredAny(),
  currency: ValidationUtils.requiredAny(),
  paymentMode: ValidationUtils.requiredAny(),
  companyBank: ValidationUtils.requiredAny()
});

const receivableSchema = yup.object().shape({
  accountReceivable: ValidationUtils.requiredAny(),
  currency: ValidationUtils.requiredAny(),
  paymentMode: ValidationUtils.requiredAny(),
  companyBank: ValidationUtils.requiredAny()
});

type MapOptions = {
  [key in AdviceType]: {
    url: TTypeAdvice;
    path: string;
    endPoint: string;
    type: 'ap' | 'ar';
    source: SourceType;
    webTable: WebTable;
    invoiceScreen: 'AP' | 'AR';
    account:
      | IPaymentAdviceFormData['accountPayable']
      | IReceivableAdviceFormData['accountReceivable'];
    accountAddress: string;
    status: string;
    schema: yup.AnyObjectSchema;
    titlePopup: string;
    tableName: TableName;
    title: string;
  };
};

export const kind: MapOptions = {
  [AdviceType.Payment]: {
    url: TTypeAdvice.paymentAdvice,
    path: 'ap-charge-code',
    webTable: WebTable.ACCOUNTING_PAYMENT_ADVICE,
    endPoint: ENDPOINTS.paymentAdvice(),
    type: 'ap',
    source: SourceType.PaymentAdvice,
    invoiceScreen: 'AP',
    account: 'accountPayable',
    accountAddress: 'accountPayableAddress',
    status: 'paymentAdviceStatus',
    schema: payableSchema,
    titlePopup: 'add_ap_invoice',
    tableName: TableName.ACCOUNTING_PAYMENT_ADVICE,
    title: 'payment_advice_summary'
  },
  [AdviceType.Receivable]: {
    url: TTypeAdvice.receivableAdvice,
    path: 'ar-charge-code',
    webTable: WebTable.ACCOUNTING_RECEIVABLE_ADVICE,
    endPoint: ENDPOINTS.receivableAdvice(),
    type: 'ar',
    source: SourceType.ReceivableAdvice,
    invoiceScreen: 'AR',
    account: 'accountReceivable',
    accountAddress: 'accountReceivableAddress',
    status: 'receivableAdviceStatus',
    schema: receivableSchema,
    titlePopup: 'add_ar_invoice',
    tableName: TableName.ACCOUNTING_RECEIVABLE_ADVICE,
    title: 'receivable_advice_summary'
  }
};

export const kindColumnDefs = {
  'receivable-advice': {
    headerNameAmount: 'amount_received',
    headerNameAble: 'receivable_acc',
    headerNameCode: 'ar_code',
    headerName: 'ar_name',
    arApType: ArApType.Ar,
    able: 'Receivable',
    account: 'revenue',
    isPayment: false,
    title: 'collect'
  },
  'payment-advice': {
    headerNameAmount: 'amount_paid',
    headerNameAble: 'payable_acc',
    headerNameCode: 'ap_code',
    headerName: 'ap_name',
    arApType: ArApType.Ap,
    able: 'Payable',
    account: 'payable',
    isPayment: true,
    title: 'paid'
  }
};

export const onShowInvoiceList = (data: IInvoiceListProps) => {
  const _kind = kind[data.adviceType];
  return UIUtils.popup.open({
    title: trans(_kind.titlePopup),
    maxWidth: false,
    content: () => <InvoiceList {...data} />
  });
};

export const useChargeCodePayment = ({
  adviceType
}: {
  adviceType: AdviceType;
}) => {
  const _kind = kind[adviceType];
  return useMutationEnhancer<
    any[],
    {
      invoiceIds: number[];
    }
  >({
    mutationFn: async params => {
      const res = await APIManager.request({
        url: ENDPOINTS.advice(_kind.url, _kind.path),
        body: {
          [`${_kind.type}InvoiceIds`]: params.invoiceIds,
          includeFields: [
            'chargeCode',
            'revenueAccount',
            'paymentAccount',
            'accountPayable',
            'accountReceivable',
            'taxType'
          ]
        }
      });
      return res.data?.data;
    }
  });
};

export type IFormData = IPaymentAdviceFormData | IReceivableAdviceFormData;

export interface IFormItem {
  fields?: any;
}

export const useAdviceFormContext = () => {
  const methods = useFormContext<IFormData>();

  const [details, defStatus] = useWatch({
    control: methods.control,
    name: ['adviceDetails', 'defaultAdviceStatus']
  });

  const wrapWithDetails = useCallback(
    (field: ControllerRenderProps<IFormData, any>, cb?: (e?: any) => void) =>
      (e: any) => {
        if (details.length > 0) {
          wrapWithAlert({
            msg: trans('message.all_invoice_will_be_deleted'),
            cb: () => {
              methods.setValue('adviceDetails', []);
              field.onChange(e);
              field.onBlur();
              cb?.(e);
            }
          })();
        } else {
          field.onChange(e);
          field.onBlur();
          cb?.(e);
        }
      },
    [details?.length, methods]
  );

  const isDone = useMemo(() => {
    return [AdviceStatus.Approved, AdviceStatus.Complete].includes(defStatus);
  }, [defStatus]);

  return { ...methods, wrapWithDetails, isDone };
};
