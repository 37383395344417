import { snakeCase } from 'lodash';
import React, { memo, useCallback } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { ENDPOINTS } from '@constants';
import trans from 'translation';
import { KGrid, KInput } from 'uikit';

import { IFormData } from './helpers';

interface IProps {
  name: 'costingTaxType' | 'sellingTaxType';
}
function FormTax({ name }: IProps) {
  const methods = useFormContext<IFormData>();
  const [isSystem] = useWatch({
    control: methods.control,
    name: ['isSystem']
  });

  const convertLabel = useCallback(
    (_lab: string) => {
      return trans(snakeCase(`${_lab}_${name}`));
    },
    [name]
  );

  return (
    <>
      <KGrid.Item xs={2.5}>
        <Controller
          name={name}
          control={methods.control}
          render={({ field, fieldState: { error } }) => {
            return (
              <KInput.Autocomplete
                {...field}
                label={convertLabel('default_tax')}
                apiURL={ENDPOINTS.taxType()}
                apiParams={{
                  includeFields: ['collectedTaxAccount', 'paidTaxAccount']
                }}
                getOptionLabel={(o: any) => o?.code ?? ''}
                inputProps={{
                  required: true,
                  message: error?.message
                }}
                disabled={isSystem}
              />
            );
          }}
        />
      </KGrid.Item>

      <KGrid.Item xs={2.5}>
        <Controller
          name={name}
          control={methods.control}
          render={({ field: { value } }) => {
            return (
              <KInput.TextField
                name="taxValue"
                label={convertLabel('default_tax_value')}
                value={value?.defaultRate ?? ''}
                disabled
              />
            );
          }}
        />
      </KGrid.Item>

      <KGrid.Item xs={3.5}>
        <Controller
          name={name}
          control={methods.control}
          render={({ field: { value } }) => {
            return (
              <KInput.TextField
                label={trans('collected_tax_account')}
                value={value?.collectedTaxAccount?.displayName ?? ''}
                disabled
              />
            );
          }}
        />
      </KGrid.Item>

      <KGrid.Item xs={3.5}>
        <Controller
          name={name}
          control={methods.control}
          render={({ field: { value } }) => {
            return (
              <KInput.TextField
                label={trans('paid_tax_account')}
                value={value?.paidTaxAccount?.displayName ?? ''}
                disabled
              />
            );
          }}
        />
      </KGrid.Item>
    </>
  );
}

export default memo(FormTax);
