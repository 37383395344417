import { ColDef } from 'ag-grid-community';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { compact, isEmpty } from 'lodash';

import { Prototype } from 'core';

interface IExportParams {
  colDefs: ColDef<any>[];
  header?: any[];
  footer?: any[];
}

// const ALIGNMENT_STYLE = {
//     wrapText: true
// };

const FONT_STYLE = {
  name: 'Calibri',
  size: 12
};

// const getTextWidth = (text: string) => {
//   const canvas = document.createElement('canvas');
//   const ctx = canvas.getContext('2d');
//   if (ctx) {
//     ctx.font = '12px Arial';
//     return ctx.measureText(text).width;
//   }
//   return text.length;
// };

const MIN_WIDTH = 16; // Font size = 10

export const exportDataToExcel = async (
  name: string,
  data: string[][],
  exportParams: IExportParams
) => {
  if (data.length === 0) {
    return undefined;
  }

  const { colDefs, header, footer } = exportParams;

  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet(name);

  const rowCount = data.length;
  const columnCount = data[0].length;

  let headerRowCount = 0;

  // Header
  if (header) {
    const _header = compact(header);
    if (!isEmpty(_header)) {
      headerRowCount = _header.length;
      for (let i = 0; i < headerRowCount; i++) {
        const fData = _header[i].data ?? [];
        const row = sheet.addRow(fData);
        if (_header[i].mergeCells) {
          sheet.mergeCells(_header[i].mergeCells);
        }
        row.font = {
          ...FONT_STYLE,
          bold: !!_header[i].mergeCells
        };
        // for (let j = 0; j < fData.length; j++) {
        //   if (_header[i].mergeCells) {
        //     sheet.mergeCells(_header[i].mergeCells);
        //   }
        //   const cell = sheet.getCell(i + 1, j + 1);
        //   cell.value = fData[j];
        //   cell.font = {
        //     ...FONT_STYLE,
        //     bold: !!_header[i].mergeCells,
        //     name: !!_header[i].mergeCells ? 'Arial' : 'Arial (Body)'
        //   };
        // }
      }

      sheet.addRow([]);
    }
  }

  // Body
  for (let i = 0; i < rowCount; i++) {
    const row = i === 0 ? sheet.addRow(data[i]) : sheet.addRow([]);
    row.font = {
      ...FONT_STYLE,
      bold: i === 0
    };
    if (i > 0) {
      data[i].forEach((d, idx) => {
        const cell = row.getCell(idx + 1);
        const colDefType = colDefs[idx].type;
        const type =
          colDefType &&
          ['tax', 'number', 'exchangeRate', 'numberWoPad', 'integer'].includes(
            colDefType as string
          )
            ? 'number'
            : 'string';

        if (type === 'number') {
          cell.value = !Prototype.core.isNullOrUndefined(d)
            ? parseFloat(d.replace(/,/g, ''))
            : d;
          cell.numFmt =
            colDefType === 'numberWoPad' || colDefType === 'tax'
              ? '#,##0.###'
              : colDefType === 'exchangeRate'
              ? '#,##0.############'
              : colDefType === 'integer'
              ? '#,##0'
              : '#,##0.000';
        } else {
          cell.value = d;
        }
      });
    }
  }

  sheet.addRow([], 'i');

  // Column widths
  for (let i = 0; i < columnCount; i++) {
    const minWidth = ((colDefs[i].width ?? 0) * MIN_WIDTH) / 130;
    sheet.getColumn(i + 1).width = minWidth;
  }

  // Footer
  if (footer) {
    const _footer = compact(footer);
    const footerRowCount = _footer.length;
    for (let i = 0; i < footerRowCount; i++) {
      const rowIndex = headerRowCount + 1 + rowCount + 1 + i + 1;
      const fData = _footer[i].data ?? [];
      for (let j = 0; j < fData.length; j++) {
        const cell = sheet.getCell(rowIndex, j + 1);
        cell.value = fData[j];
        cell.font = {
          ...FONT_STYLE
        };
      }
    }
  }

  // Export
  try {
    const buffer = await workbook.xlsx.writeBuffer();
    const eData = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    });

    saveAs(eData, name);
  } catch (error) {
    console.error('Error exporting Excel file:', error);
  }
};
