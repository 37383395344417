import React, { memo, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AirOceanType,
  ENDPOINTS,
  TABLE_BODY_MAX_HEIGHT_WITH_TABS,
  TableName,
  WebTable
} from '@constants';
import DataTable from 'containers/DataTable';
import { useDataTable, useGlobalTable } from 'hooks';
import { useDocumentTitle } from 'hooks/useDocumentTitle/use-document-title';
import trans from 'translation';
import { KBreadcrumbs, KColors, KContainer, KLabel } from 'uikit';
import { TableUtils, mappedServiceStatus } from 'utils';
import { showNoteContent } from 'utils/common';

import { useDeleteService } from './helper';

const Service = () => {
  const { onDelete, deleteLoading } = useDeleteService();

  useDocumentTitle('Service | Apollogix');

  const navigate = useNavigate();

  const columnsFunc = useCallback(
    (data: any[]) => {
      return [
        {
          label: trans('action'),
          name: 'action',
          options: TableUtils.options.actionWithMenuList(data, item => [
            {
              title: trans('edit'),
              icon: 'EditOutlined',
              onPress: () => {
                navigate(`${item.id}`);
              }
            },
            {
              title: trans('delete'),
              icon: 'Delete',
              onPress: () => onDelete?.([item])
            }
          ])
        },
        {
          label: trans('service_no'),
          name: 'code',
          options: {
            ...TableUtils.options.base2XLg,
            customBodyRender: (v: string, rowData: any) => {
              const item = data[rowData.rowIndex];
              return (
                <KLabel.Text
                  onPress={() => {
                    window.open(`service/${item.id}`, '_blank');
                  }}
                >
                  {v}
                </KLabel.Text>
              );
            }
          }
        },
        {
          label: trans('hbl_hawb_no'),
          name: 'hblNo',
          options: TableUtils.options.baseXLg
        },
        {
          label: trans('mbl_mawb_no'),
          name: 'oblNo',
          options: TableUtils.options.baseXLg
        },
        {
          label: trans('service_type'),
          name: 'serviceType',
          options: {
            ...TableUtils.options.baseCenter,
            sort: false,
            customBodyRenderLite: (index: number) => {
              const rowData = data?.[index];
              if (rowData) {
                const { isCustoms, isHandling, isTransport } = rowData;
                return (
                  <KContainer.View row center gap="0.25rem">
                    <KContainer.RenderWhen limit={3}>
                      <KContainer.RenderWhen.If isTrue={isCustoms}>
                        <KContainer.View
                          paddingH="0.25rem"
                          background={'#DEFFFD'}
                          brW={1}
                          brC={KColors.primary.normal}
                          width="fit-content"
                          br={'x'}
                        >
                          <KLabel.Text color={KColors.primary.normal}>
                            CU
                          </KLabel.Text>
                        </KContainer.View>
                      </KContainer.RenderWhen.If>

                      <KContainer.RenderWhen.If isTrue={isTransport}>
                        <KContainer.View
                          paddingH="0.25rem"
                          background={'#DEFFFD'}
                          brW={1}
                          brC={KColors.primary.normal}
                          width="fit-content"
                          br={'x'}
                        >
                          <KLabel.Text color={KColors.primary.normal}>
                            TR
                          </KLabel.Text>
                        </KContainer.View>
                      </KContainer.RenderWhen.If>

                      <KContainer.RenderWhen.If isTrue={isHandling}>
                        <KContainer.View
                          paddingH="0.25rem"
                          background={'#DEFFFD'}
                          brW={1}
                          brC={KColors.primary.normal}
                          width="fit-content"
                          br={'x'}
                        >
                          <KLabel.Text color={KColors.primary.normal}>
                            HA
                          </KLabel.Text>
                        </KContainer.View>
                      </KContainer.RenderWhen.If>
                    </KContainer.RenderWhen>
                  </KContainer.View>
                );
              }
              return null;
            }
          }
        },

        {
          label: trans('shipment_no'),
          name: 'shipmentNumber',
          options: {
            customBodyRender: (v: string, rowData: any) => {
              const item = data?.[rowData.rowIndex];
              const to = item?.consolId
                ? `/consolidation/${item?.consolId}/shipment/${item?.shipmentId}`
                : `/shipment/${item?.shipmentId}`;
              const baseOceanOrAir =
                item?.freightMode === AirOceanType.Ocean
                  ? '/ocean-freight'
                  : '/air-freight';
              return (
                <KLabel.Text
                  onPress={() => {
                    window.open(`/admin${baseOceanOrAir}${to}`);
                  }}
                >
                  {v}
                </KLabel.Text>
              );
            }
          }
        },
        {
          label: trans('status'),
          name: 'serviceStatus',
          options: TableUtils.options.chips({
            uppercase: true,
            width: 152,
            css: { textAlign: 'center' },
            mapLabelData: mappedServiceStatus()
          })
        },
        {
          label: trans('booking_client_code'),
          name: 'bookingClient.code'
        },
        {
          label: trans('booking_client_name'),
          name: 'bookingClient.name'
        },
        {
          label: trans('container_no'),
          name: 'containerNumberList',
          options: TableUtils.options.withMoreIcon({
            maxLength: 1,
            onNormalizeData: rowData => {
              const item = data?.[rowData.rowIndex];
              if (item?.containerNumberList) {
                return item?.containerNumberList?.split(',');
              }

              return [];
            }
          })
        },
        {
          label: trans('cds_no'),
          name: 'customDeclarationSheetList',
          options: TableUtils.options.withMoreIcon({
            maxLength: 1,
            onNormalizeData: rowData => {
              const item = data?.[rowData.rowIndex];
              if (item?.customDeclarationSheetList) {
                return item?.customDeclarationSheetList?.split(',');
              }

              return [];
            }
          })
        },
        {
          label: trans('job_order_no'),
          name: 'jobOrderNumber'
        },
        {
          label: trans('sales_person'),
          name: 'picSalesPerson.staff.displayName',
          options: {
            sort: false
          }
        },
        {
          label: trans('pic_customs'),
          name: 'picCustoms.staff.displayName',
          options: {
            sort: false
          }
        },
        {
          label: trans('pic_transport'),
          name: 'picTransport.staff.displayName',
          options: {
            sort: false
          }
        },
        {
          label: trans('created_by'),
          name: 'createdUsername'
        },
        {
          label: trans('updated_by'),
          name: 'updatedUsername'
        },
        {
          label: trans('created_at'),
          name: 'createdAt'
        },
        {
          label: trans('updated_at'),
          name: 'updatedAt'
        },
        {
          label: trans('general_note'),
          name: 'noteContent',
          options: TableUtils.options.contentMore((item: any) => {
            showNoteContent(item);
          }, data)
        }
      ];
    },
    [navigate, onDelete]
  );

  const otherParams = useMemo(() => {
    return {
      includeFields: [
        'bookingClient',
        'picSalesPerson',
        'picCustoms',
        'picTransport'
      ],

      isReconcile: true
    };
  }, []);

  const mappedFields = useMemo(() => {
    return {
      'bookingClient.code': 'bookingClientCode',
      'bookingClient.name': 'bookingClientName',
      'picSalesPerson.staff.displayName': 'picSalesPersonName',
      'picCustoms.staff.displayName': 'picCustomsName',
      'picTransport.staff.displayName': 'picTransportName'
    };
  }, []);

  const table = useDataTable({
    name: WebTable.SERVICE,
    tableName: TableName.SERVICE,
    apiURL: ENDPOINTS.service(),
    columnsFunc,
    otherOptions: {
      tableBodyMaxHeight: TABLE_BODY_MAX_HEIGHT_WITH_TABS
    },
    otherParams,
    mappedFields
  });

  useGlobalTable(table);

  return (
    <>
      <KBreadcrumbs breadcrumbs={[trans('service')]} />

      <KContainer.Card margin="0.75rem" marginT="0.25rem">
        <DataTable
          {...table}
          onAdd={() => navigate('./new')}
          onDelete={onDelete}
          isModifying={deleteLoading}
        />
      </KContainer.Card>
    </>
  );
};

export default memo(Service);
