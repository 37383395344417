import { ColDef, ColGroupDef } from 'ag-grid-community';

import { AdviceType, TTypeAdvice } from '@constants';
import { kindColumnDefs } from 'pages/Accounting/Advice/helpers';
import trans from 'translation';
import { mappedPaymentStatusData, mappedSyncStatus } from 'utils';
import AgUtils from 'utils/ag';

export const colDefs = ({
  adviceType
}: {
  adviceType: AdviceType;
}): ColDef<any>[] | ColGroupDef<any>[] => {
  const advice =
    adviceType === AdviceType.Payment
      ? TTypeAdvice.paymentAdvice
      : TTypeAdvice.receivableAdvice;

  const _kind = kindColumnDefs[advice];

  return [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      maxWidth: 50,
      pinned: 'left',
      filter: false,
      sortable: false,
      lockVisible: true,
      colId: 'checkbox'
    },
    {
      headerName: trans('invoice_no'),
      field: 'code',
      minWidth: 193,
      pinned: true
    },
    {
      headerName: trans('consol_no'),
      field: 'consolNumber',
      minWidth: 169
    },
    {
      headerName: trans('shipment_no'),
      field: 'shipmentNumber',
      minWidth: 140
    },
    {
      headerName: trans('service_no'),
      field: 'serviceNumber',
      minWidth: 140
    },
    ...(_kind.isPayment
      ? [
          {
            headerName: trans('ap_client_inv_no'),
            field: 'refApInvoiceNo'
          },
          {
            headerName: trans('ap_client_inv_date'),
            field: 'refApInvoiceDate',
            minWidth: 140,
            type: 'vnDate'
          }
        ]
      : []),
    {
      headerName: trans('ref_number'),
      field: 'refNo'
    },
    {
      headerName: trans('invoice_type'),
      field: 'invoiceType',
      minWidth: 100,
      ...AgUtils.options.chip()
    },
    {
      headerName: trans('invoice_status'),
      field: 'invoiceStatus.name',
      minWidth: 119,
      ...AgUtils.options.chip()
    },
    ...(_kind.isPayment
      ? [
          {
            headerName: trans('obh_code'),
            field: 'onBehalfClient.code'
          },
          {
            headerName: trans('obh_name'),
            field: 'onBehalfClient.name',
            minWidth: 200
          }
        ]
      : []),
    {
      headerName: trans('payment_status'),
      field: 'paymentStatus',
      minWidth: 119,
      ...AgUtils.options.chips({
        uppercase: true,
        width: 90,
        mapLabelData: mappedPaymentStatusData()
      })
    },
    {
      headerName: trans('sync_status'),
      field: 'syncStatus',
      minWidth: 119,
      ...AgUtils.options.chips({
        uppercase: true,
        mapLabelData: mappedSyncStatus()
      })
    },
    {
      headerName: trans(_kind.headerNameCode),
      // 'accountPayable.code' || 'accountReceivable.code'
      field: `account${_kind.able}.code`
    },
    {
      headerName: trans(_kind.headerName),
      // 'accountPayable.name' || 'accountReceivable.name'
      field: `account${_kind.able}.name`,
      width: 205
    },
    {
      headerName: trans('create_date'),
      field: 'createdAt',
      type: 'vnDate'
    },
    {
      headerName: trans('currency'),
      field: 'paymentCurrency.code'
    },
    {
      headerName: trans('sub_total'),
      field: 'subTotal',
      ...AgUtils.options.number()
    },
    {
      headerName: trans('tax_amount'),
      field: 'taxAmount',
      ...AgUtils.options.number()
    },
    {
      headerName: trans('total_amount'),
      field: 'totalAmount',
      ...AgUtils.options.number()
    },
    {
      headerName: trans('balance'),
      field: 'balance',
      ...AgUtils.options.number()
    },
    {
      headerName: trans(_kind.title),
      field: _kind.title,
      ...AgUtils.options.number()
    },
    {
      headerName: trans('due_date'),
      field: 'dueDate',
      type: 'vnDate'
    },
    {
      headerName: trans('last_payment'),
      field: 'lastPaymentDate',
      type: 'vnDate'
    }
  ];
};
