import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import React from 'react';

const SalesList = loadable(() => pMinDelay(import('pages/JobOrder'), 200));
const JobOrderLayout = loadable(() =>
  pMinDelay(import('pages/JobOrder/DetailsLayout'), 200)
);
const JobOrderDetails = loadable(() =>
  pMinDelay(import('pages/JobOrder/Details/'), 200)
);

const Rate = loadable(() => pMinDelay(import('pages/JobOrder/Rate'), 200));

const SalesWip = loadable(() => pMinDelay(import('pages/JobOrder/Wip'), 200));
const AlertNote = loadable(() =>
  pMinDelay(import('pages/JobOrder/AlertNote'), 200)
);
const EmailEdoc = loadable(() =>
  pMinDelay(import('pages/JobOrder/EmailEdoc'), 200)
);

const SalesLog = loadable(() =>
  pMinDelay(import('pages/JobOrder/LogWrapper'), 200)
);

import { GroupSales } from '@constants';

import { IAdminRoute } from './helpers';

const sales: IAdminRoute = {
  name: 'Sales',
  path: 'sales',
  icon: 'currency_exchange',
  menu: [GroupSales.SALE_JOB_ORDER_DETAIL_LIST],
  children: [
    {
      parentKey: 'sales',
      name: 'Job Order',
      path: 'job-order',
      icon: 'currency_exchange',
      menu: [GroupSales.SALE_JOB_ORDER_DETAIL_LIST],
      children: [
        {
          index: true,
          element: <SalesList />
        },
        {
          path: 'new',
          element: <JobOrderLayout key="new" />,

          children: [
            {
              index: true,
              element: <JobOrderDetails key={'new'} />
            }
          ]
        },
        {
          path: ':jobOrderId',
          element: <JobOrderLayout />,

          children: [
            {
              index: true,
              element: <JobOrderDetails key={':jobOrderId'} />,
              code: [GroupSales.SALE_JOB_ORDER_DETAIL_DETAIL]
            },
            {
              path: 'rate',
              element: <Rate />
            },
            {
              path: 'wip',
              element: <SalesWip />
            },
            {
              path: 'alert-note',
              element: <AlertNote />
            },
            {
              path: 'document',
              element: <EmailEdoc />
            },
            {
              path: 'history',
              element: <SalesLog />,
              code: [GroupSales.SALE_JOB_ORDER_LOG_LIST]
            }
          ]
        }
      ]
    }
  ]
};

export default sales;
