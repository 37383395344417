/* eslint-disable max-lines */
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  ArApType,
  ENDPOINTS,
  InvoiceCategory,
  InvoiceType,
  LinkedAccountCode,
  LinkedAccountType,
  PAGE_SIZE_DEFAULT_MAX,
  QUERY_KEYS,
  SyncMisa,
  WebTable,
  tableRef
} from '@constants';
import APIManager from '@services';
import { useFetchSettings } from 'hooks/ssLibrary/setting';
import {
  KeyInvoiceEnum,
  urlSelectedTransactions
} from 'pages/Accounting/Bankingg/helpers';
import { UIUtils } from 'utils';

import { useFetchLinkedChartOfAccount } from './chartOfAccount';

import {
  useCUDMutationEnhancer,
  useGenericMutationEnhancer,
  useMutationEnhancer,
  useQueryEnhancer,
  useResolverForm
} from '../core';

export * from './chartOfAccount';
export * from './advice';
export * from './wip';
export * from './invoice';
export * from './statement';

export const useFetchBankTransactionDetails = (
  bankTransactionId?: number | string
) => {
  return useQueryEnhancer({
    queryKey: [QUERY_KEYS.bankTransaction, bankTransactionId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.bankTransaction(':bankTransactionId', {
          bankTransactionId
        })
      });

      return res?.data;
    },
    enabled: !!bankTransactionId
  });
};

export const useFetchMatchedTransactions = (transactionId?: number) => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.matchedTransaction, transactionId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.bankTransaction(':transactionId/match-transaction', {
          transactionId
        })
      });

      return res?.data ?? [];
    }
  });
};

export const useCUDMatchReconcile = (transactionId?: number) => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.bankTransaction(':transactionId/match-reconcile', {
      transactionId
    }),
    webTable: WebTable.BANKING_BANK_MATCHED,
    dependentWebTable: [WebTable.BANKING_BANK_TRANSACTION]
  });
};

export const useCUDBankTransaction = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.bankTransaction(),
    webTable: WebTable.BANKING_BANK_TRANSACTION,
    dependentWebTable: [WebTable.BANKING_SELECTED_TRANSACTION]
  });
};

export const useUnreconciled = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.bankTransaction(),
    webTable: WebTable.ACCOUNTING_BANKING_RECONCILE
  });
};

export const useCUDSelectReconcile = (transactionId?: number) => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.bankTransaction(':transactionId/select-reconcile', {
      transactionId
    }),
    webTable: WebTable.BANKING_BANK_TRANSACTION,
    dependentWebTable: [
      WebTable.BANKING_BANK_TRANSACTION,
      WebTable.BANKING_BANK_MATCHED
    ]
  });
};

export const useValidateImportBankTransaction = () => {
  return useMutationEnhancer<any, FormData>({
    mutationFn: async (formData: FormData) => {
      const res = await APIManager.request({
        url: ENDPOINTS.bankTransaction('import-validate'),
        method: 'POST',
        showToast: true,
        body: formData,
        configs: {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      });
      return res.data;
    }
  });
};

export const useImportBankTransaction = () => {
  return useMutationEnhancer<any, FormData>({
    mutationFn: async formData => {
      const res = await APIManager.request({
        url: ENDPOINTS.bankTransaction('import-excel'),
        method: 'POST',
        showToast: true,
        body: formData,
        configs: {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      });
      return res.data;
    },
    onSuccess: () => {
      tableRef?.[WebTable.BANKING_BANK_TRANSACTION]?.init?.();
      UIUtils.popup.dismiss();
    }
  });
};

export const useFetchDataAddSelectedTransaction = (
  keyUrl: KeyInvoiceEnum,
  bankTransactionId?: number | string,
  clientId?: number | string,
  excludeIds?: number[]
) => {
  return useQueryEnhancer<any[]>({
    queryKey: [QUERY_KEYS.bankTransaction, keyUrl, bankTransactionId, clientId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: urlSelectedTransactions[keyUrl],
        body: {
          clientId,
          bankTransactionId,
          size: PAGE_SIZE_DEFAULT_MAX,
          includeFields: ['accountReceivable', 'accountPayable'],
          excludeIds
        }
      });

      return res?.data?.data;
    },
    enabled: !!keyUrl && !!bankTransactionId
  });
};

interface IDueDateParams {
  arApType: ArApType;
  arApId?: number | string;
  consolId?: number | string;
  shipmentId?: number | string;
  serviceId?: number | string;
}

interface IDueDateResponse {
  dueDate?: any;
  dueDates?: any[];
  currency?: any;
  paymentMode?: any;
  creditLimit?: number;
  apAddress?: any;
  defaultDueDateType?: any;
  isOneDueDate: boolean;
  arAddress?: any;
  isExceededBalanceDue?: boolean;
  creditFrom?: any;
  dueDateType?: string;
}

export const CalculateDueDate = (data: IDueDateResponse | null) => {
  if (!isEmpty(data)) {
    const isOneDueDate = (data.dueDates ?? []).length < 2;

    const defaultDueDateType = isOneDueDate
      ? data.dueDates?.[0]
      : {
          creditFrom: data.creditFrom,
          dueDate: data.dueDate,
          dueDateType: data.dueDateType
        };

    return { ...data, isOneDueDate, defaultDueDateType };
  }

  return null;
};

export const useFetchAccountingDueDateData = ({
  arApType,
  arApId, // Client ID
  consolId,
  shipmentId,
  serviceId
}: IDueDateParams) => {
  return useQueryEnhancer<IDueDateResponse | null>({
    queryKey: [
      QUERY_KEYS.accountingDueDateData,
      arApType,
      arApId,
      consolId,
      shipmentId,
      serviceId
    ],
    queryFn: async () => {
      const category = !!serviceId
        ? InvoiceCategory.Service
        : !!shipmentId
        ? InvoiceCategory.Billing
        : InvoiceCategory.Accounting;

      const isAr = arApType === ArApType.Ar;

      const { data } = await APIManager.request({
        url: ENDPOINTS.accounting(
          {
            category,
            type: arApType
          },
          'calculate-due-date'
        ),
        body: {
          accountPayableId: !isAr ? arApId : undefined,
          accountReceivableId: isAr ? arApId : undefined,
          consolId,
          shipmentId,
          serviceId
        }
      });

      return CalculateDueDate(data);
    },
    enabled: (!!consolId || !!shipmentId || !!serviceId) && !!arApId
  });
};

export const useFetchDefaultDueDateData = ({
  arApType,
  arApId, // Invoice ID
  consolId,
  shipmentId,
  serviceId
}: IDueDateParams) => {
  return useQueryEnhancer<IDueDateResponse | null>({
    queryKey: [
      QUERY_KEYS.invoiceDueDateData,
      arApType,
      arApId,
      consolId,
      shipmentId,
      serviceId
    ],
    queryFn: async () => {
      const category = !!serviceId
        ? InvoiceCategory.Service
        : !!shipmentId
        ? InvoiceCategory.Billing
        : InvoiceCategory.Accounting;

      const isAr = arApType === ArApType.Ar;

      const { data } = await APIManager.request({
        url: ENDPOINTS.accounting(
          {
            category,
            type: arApType
          },
          'calculate-due-date'
        ),
        body: {
          apInvoiceId: !isAr ? arApId : undefined,
          arInvoiceId: isAr ? arApId : undefined,
          consolId,
          shipmentId,
          serviceId
        }
      });

      return CalculateDueDate(data);
    },
    enabled: (!!consolId || !!shipmentId || !!serviceId) && !!arApId
  });
};

interface IApThresholdParams
  extends Pick<IDueDateParams, 'consolId' | 'serviceId' | 'shipmentId'> {}

export const useFetchApThreshold = ({
  consolId,
  shipmentId,
  serviceId
}: IApThresholdParams) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.apThreshold, consolId, shipmentId, serviceId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: !!serviceId
          ? ENDPOINTS.service(':id/check-threshold', { id: serviceId })
          : !!shipmentId
          ? ENDPOINTS.shipment(':id/check-threshold', { id: shipmentId })
          : ENDPOINTS.consol(':id/check-threshold', { id: consolId }),
        showError: false
      });

      return res.data;
    },
    enabled: !!consolId || !!shipmentId || !!serviceId
  });
};

export const useFetchBankAccountByChartAccount = () => {
  const [returnData, setReturnData] = useState<any>(undefined);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, ...rest } = useMutationEnhancer<any, number>({
    mutationFn: async chartOfAccountId => {
      if (!chartOfAccountId) {
        return undefined;
      }

      const res = await APIManager.request({
        url: ENDPOINTS.companyBankAccount(),
        body: {
          size: 1,
          chartOfAccountId
        }
      });
      return res.data?.data?.[0];
    },
    onSuccess: res => {
      setReturnData(res);
    }
  });

  return {
    bank: returnData,
    ...rest
  };
};

interface IUseInvoiceDataChangedParams {
  invoiceType?: InvoiceType;
  currency?: any;
  apId?: number;
  consolId?: number | string;
  shipmentId?: number | string;
  serviceId?: number | string;
  isEdit?: boolean;
  isOverrideDueDate?: boolean;
}

const onCheckKey = (
  methods: ReturnType<typeof useResolverForm<any>>,
  field: string
) => {
  const v = methods.getValues();
  const hasKey = Object.keys(v).includes(field);
  return hasKey;
};

export const useInvoiceDataChanged = (
  methods: ReturnType<typeof useResolverForm<any>>,
  arApType: ArApType,
  v: IUseInvoiceDataChangedParams
) => {
  const {
    currency,
    invoiceType,
    apId,
    shipmentId,
    consolId,
    serviceId,
    isEdit
  } = v || {};

  const { data: dueDateData, refetch } = useFetchAccountingDueDateData({
    arApType,
    arApId: apId,
    shipmentId,
    consolId,
    serviceId
  });

  const { data: defAccounts } = useFetchLinkedChartOfAccount();

  const { mutate, bank } = useFetchBankAccountByChartAccount();

  const { data: settings } = useFetchSettings();

  const isAr = arApType === ArApType.Ar;
  const isOBH = invoiceType === InvoiceType.OBH;

  const chartOfAccountField = isAr
    ? 'chartOfAccountReceivable'
    : 'chartOfAccountPayable';

  const defAccount = useMemo(() => {
    const hasData = ['VND', 'USD'].includes(currency?.code);

    const arDefAccount = defAccounts
      ? isOBH
        ? defAccounts[LinkedAccountType.Sales].find(
            i => i.code === LinkedAccountCode.SalesAssetOBH
          )?.chartOfAccount
        : hasData
        ? defAccounts[LinkedAccountType.Sales].find(i =>
            currency?.code === 'USD'
              ? i.code === LinkedAccountCode.SalesAssetUSD
              : i.code === LinkedAccountCode.SalesAssetVND
          )?.chartOfAccount
        : undefined
      : undefined;

    const apDefAccount = defAccounts
      ? isOBH
        ? defAccounts[LinkedAccountType.Purchases].find(
            i => i.code === LinkedAccountCode.PurchasesAssetOBH
          )?.chartOfAccount
        : hasData
        ? defAccounts[LinkedAccountType.Purchases].find(i =>
            currency?.code === 'USD'
              ? i.code === LinkedAccountCode.PurchasesAssetUSD
              : i.code === LinkedAccountCode.PurchasesAssetVND
          )?.chartOfAccount
        : undefined
      : undefined;

    return isAr ? arDefAccount : apDefAccount;
  }, [currency?.code, defAccounts, isOBH, isAr]);

  useEffect(() => {
    const hasData = ['VND', 'USD'].includes(currency?.code);

    const arDefAccountBankId =
      defAccounts && hasData
        ? defAccounts[LinkedAccountType.Sales].find(i =>
            currency?.code === 'USD'
              ? i.code === LinkedAccountCode.SalesBankUSD
              : i.code === LinkedAccountCode.SalesBankVND
          )?.chartOfAccountId
        : undefined;

    const apDefAccountBankId =
      defAccounts && hasData
        ? defAccounts[LinkedAccountType.Purchases].find(i =>
            currency?.code === 'USD'
              ? i.code === LinkedAccountCode.PurchasesBankUSD
              : i.code === LinkedAccountCode.PurchasesBankVND
          )?.chartOfAccountId
        : undefined;

    const defAccountBankId = isAr ? arDefAccountBankId : apDefAccountBankId;

    mutate(defAccountBankId);
  }, [currency?.code, defAccounts, isAr, mutate]);

  useEffect(() => {
    if (!isEdit && onCheckKey(methods, chartOfAccountField)) {
      methods.setValue(chartOfAccountField, defAccount ?? null);
    }
  }, [chartOfAccountField, defAccount, methods, currency?.id, isEdit]);

  useEffect(() => {
    if (!isEdit && onCheckKey(methods, 'companyBank')) {
      methods.setValue('companyBank', bank ?? null);
    }
  }, [methods, currency?.id, isEdit, bank]);

  const onChangeDueDateData = useCallback(
    (force?: boolean) => {
      const onSuccess = (d?: any) => {
        if (!!d || !!dueDateData) {
          const _d = d ?? dueDateData;
          if (_d.isOneDueDate) {
            methods.setValue('defaultDueDate', _d.dueDate);
            if (!methods.getValues('isOverrideDueDate')) {
              methods.setValue('dueDate', _d.dueDate);
            }
          }

          methods.setValue('isOneDueDate', _d.isOneDueDate);
          methods.setValue('dueDateType', _d.defaultDueDateType ?? null);
          methods.setValue('listDueDateType', _d.dueDates);
          methods.setValue(
            'paymentCurrency',
            _d.currency ?? settings?.primaryCurrency ?? null
          );
          methods.setValue('paymentMode', _d.paymentMode ?? null);
          methods.setValue('creditLimit', _d.creditLimit ?? '');
          methods.setValue(
            'isExceededBalanceDue',
            _d.isExceededBalanceDue ?? false
          );

          if (!isAr) {
            methods.setValue('accountPayableAddress', _d.apAddress ?? null);
          } else {
            methods.setValue('arAddress', _d.arAddress ?? null);
          }
        }
      };

      if (force) {
        refetch().then(d => {
          onSuccess(d?.data);
        });
      } else {
        onSuccess();
      }
    },
    [dueDateData, isAr, methods, refetch, settings?.primaryCurrency]
  );

  useEffect(() => {
    if (!!dueDateData && !isEdit) {
      onChangeDueDateData();
    }
  }, [dueDateData, isEdit, onChangeDueDateData]);

  return { onChangeDueDateData };
};

export const useFetchAccountingDashboardSummary = (dayRange: number) => {
  return useQueryEnhancer<any | null>({
    queryKey: [QUERY_KEYS.accountingInvoiceSummary, dayRange],
    queryFn: async () => {
      const { data = [] } = await APIManager.request({
        url: ENDPOINTS.dashboardSummary('invoice-summary'),
        body: { dayRange: dayRange || '' }
      });

      return data;
    },
    enabled: !!dayRange || dayRange === 0
  });
};

export const useFetchAccountingOverdueSummary = (dayRange: number) => {
  return useQueryEnhancer<any | null>({
    queryKey: [QUERY_KEYS.accountingInvoiceOverdueSummary, dayRange],
    queryFn: async () => {
      const { data = [] } = await APIManager.request({
        url: ENDPOINTS.dashboardSummary('invoice-overdue-summary'),
        body: { dayRange: dayRange || '' }
      });

      return data;
    },
    enabled: !!dayRange || dayRange === 0
  });
};

export const useSyncMisa = (path: SyncMisa) => {
  return useGenericMutationEnhancer<any>({
    endPoint: ENDPOINTS.syncMisa(path),
    method: 'POST',
    dependentWebTable: [
      WebTable.ACCOUNTING_AP_INVOICES,
      WebTable.ACCOUNTING_AR_INVOICES,
      WebTable.STATEMENT_INVOICE
    ],
    mutationKeys: [
      [
        QUERY_KEYS.arInvoice,
        QUERY_KEYS.apInvoice,
        QUERY_KEYS.billingArInvoice,
        QUERY_KEYS.billingApInvoice,
        QUERY_KEYS.statementInvoice
      ]
    ]
  });
};
