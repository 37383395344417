import get from 'lodash/get';
import React, { memo, useCallback, useRef } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import ReactQuill from 'react-quill';
import * as yup from 'yup';

import { ENDPOINTS, Status, TermsType, generateOptions } from '@constants';
import { BottomActions } from 'components';
import { Prototype } from 'core';
import { useResolverForm } from 'hooks';
import { useCUDTermsOfCondition } from 'hooks/ssLibrary/setting';
import trans from 'translation';
import { KGrid, KInput, KListItem, KForm, KLabel, KColors } from 'uikit';
import { ValidationUtils } from 'utils';

import { useDeleteTermsOfConditionHelper } from '../helpers';

interface IFormData {
  id: string | number;
  code: string;
  name: string;
  isAir: boolean;
  isOcean: boolean;
  status: Status;
  type: TermsType;
  content: any;
  termsCategory: any;
  branchId?: string | number;
}

interface IProps {
  item?: any;
  branchId: number | string;
}

const TermsOfConditionForm = ({ branchId, item }: IProps) => {
  const editorRef = useRef<ReactQuill>(null);

  const {
    createMutation: { mutate: createMutation },
    updateMutation: { mutate: updateMutation },
    modifyLoading
  } = useCUDTermsOfCondition();

  const { onDelete, deleteLoading } = useDeleteTermsOfConditionHelper();

  const isEdit = !!item;

  const isDisabled = item?.isSystem;

  const methods = useResolverForm<IFormData>({
    schema: yup.object().shape({
      code: ValidationUtils.required(),
      name: ValidationUtils.required(),
      type: ValidationUtils.required(),
      termsCategory: ValidationUtils.requiredAny(),
      isAir: ValidationUtils.requiredCheckBoxAirOcean(),
      isOcean: ValidationUtils.requiredCheckBoxAirOcean()
    }),
    configs: {
      values: {
        id: item?.id ?? '',
        code: item?.code ?? '',
        name: item?.name ?? '',
        isAir: item?.isAir ?? true,
        isOcean: item?.isOcean ?? false,
        status: item?.status ?? Status.Active,
        type: item?.type ?? TermsType.Import,
        termsCategory: item?.termsCategory ?? null,
        content: item?.content ?? '',
        branchId
      }
    }
  });

  const err = get(methods.formState.errors, 'content');

  const [isAir, isOcean, content] = useWatch({
    control: methods.control,
    name: ['isAir', 'isOcean', 'content']
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      if (editorRef?.current?.value === '<p></p>\n') {
        return methods.setError('content', {
          message: 'validation.required.default'
        });
      }
      const mParams = {
        ...data,
        termsCategoryId: data?.termsCategory?.id,
        content: editorRef?.current?.value
      };
      if (!!mParams.id) {
        updateMutation(mParams);
      } else {
        createMutation(mParams);
      }
    },
    [createMutation, methods, updateMutation]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container>
        <KGrid.Item xs={2.4}>
          <Controller
            name="code"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  required
                  label={trans('code')}
                  message={error?.message}
                  disabled={isDisabled}
                />
              );
            }}
          />
        </KGrid.Item>
        <KGrid.Item xs={4.8}>
          <Controller
            name="name"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  required
                  label={trans('name')}
                  message={error?.message}
                  disabled={isDisabled}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4} textAlign={'center'}>
          <Controller
            name="isAir"
            control={methods.control}
            render={() => {
              return (
                <KListItem.RadioGroup
                  noSpacing
                  disabled={isDisabled}
                  data={[
                    {
                      id: 'ocean',
                      label: trans('ocean'),
                      checked: isOcean,
                      onChange: v => {
                        if (v) {
                          methods.setValue('isOcean', true);
                          methods.setValue('isAir', false);
                        }
                      }
                    },
                    {
                      id: 'air',
                      label: trans('air'),
                      checked: isAir,
                      onChange: v => {
                        if (v) {
                          methods.setValue('isOcean', false);
                          methods.setValue('isAir', true);
                        }
                      }
                    }
                  ]}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KListItem.Radio
            label={trans('is_system')}
            checked={item?.isSystem}
            disabled
            marginT="0.75rem"
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <Controller
            name="type"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  required
                  label={trans('type')}
                  options={generateOptions(TermsType)}
                  message={error?.message}
                  disabled={isDisabled}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={8}>
          <Controller
            name="termsCategory"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('terms_category')}
                  apiURL={ENDPOINTS.termsCondition()}
                  inputProps={{
                    required: true,
                    message: error?.message
                  }}
                  disabled={isDisabled}
                />
              );
            }}
          />
        </KGrid.Item>

        {!isDisabled && (
          <KGrid.Item xs={12}>
            <Controller
              name="content"
              control={methods.control}
              render={() => {
                return (
                  <>
                    <KLabel.Text>
                      Content{' '}
                      <KLabel.Text color={KColors.secondary.normal}>
                        *
                      </KLabel.Text>
                    </KLabel.Text>
                    <KInput.TextEditor
                      ref={editorRef}
                      initialBody={item?.content || content?.body || ''}
                    />
                    {err?.message && (
                      <KLabel.Error>{trans(err?.message)}</KLabel.Error>
                    )}
                  </>
                );
              }}
            />
          </KGrid.Item>
        )}

        <KGrid.Item xs={2.4}>
          <Controller
            name="status"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('status')}
                  options={generateOptions(Status)}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="createdBy"
            disabled
            label={trans('created_by')}
            value={item?.createdUsername ?? ''}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="createdAt"
            label={trans('created_at')}
            value={Prototype.date.formatDT(item?.createdAt, '')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="updatedBy"
            label={trans('updated_by')}
            value={item?.updatedUsername ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="updatedAt"
            label={trans('updated_at')}
            value={Prototype.date.formatDT(item?.updatedAt, '')}
            disabled
          />
        </KGrid.Item>
      </KGrid.Container>

      <BottomActions
        showDelete={isEdit}
        btnProps={[
          {
            onPress: () => onDelete?.([item]),
            isLoading: deleteLoading,
            disabled: isDisabled
          },
          {
            isLoading: modifyLoading
          }
        ]}
      />
    </KForm>
  );
};

export default memo(TermsOfConditionForm);
