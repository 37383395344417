import loadable from '@loadable/component';
import React from 'react';
import trans from 'translation';

import { UIUtils } from 'utils';

const Form = loadable(() => import('./Form'));

export const onShowLog = (options: { item: any }) => {
  const { item } = options;
  return UIUtils.popup.open({
    title: trans('view_history'),
    content: () => <Form item={item} />
  });
};