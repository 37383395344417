import { IAccountSetting } from '@dto';

import {
  AccountSettingType,
  ENDPOINTS,
  QUERY_KEYS,
  WebTable
} from '@constants';
import APIManager from '@services';

import {
  useCUDMutationEnhancer,
  useMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useCUDBankAccount = (id?: string | number, isAr?: boolean) => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.restrictedClient(
      ':id/bank-account/:accountSettingType',
      {
        id,
        accountSettingType: isAr ? 'AR' : 'AP'
      }
    ),
    webTable: WebTable.CLIENT_AR_BANK_ACCOUNT,
    dependentWebTable: [WebTable.CLIENT_AP_BANK_ACCOUNT]
  });
};

export const useCUDCurrencyUplift = (id?: string | number) => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.restrictedClient(':id/currency-uplift', { id }),
    webTable: WebTable.CLIENT_AR_CURRENCY_UPLIFT
  });
};

export const useFetchAccountSetting = (
  accountSettingType: AccountSettingType,
  id?: string | number
) => {
  return useQueryEnhancer<IAccountSetting[] | undefined>({
    queryKey: [QUERY_KEYS.arAp, id, accountSettingType],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.restrictedClient(
          ':id/account-setting/:accountSettingType',
          {
            id,
            accountSettingType
          }
        )
      });

      return res.data?.sort((a: any, b: any) => a.invoiceType - b.invoiceType);
    },
    enabled: !!id
  });
};

export const useModifyAccountSetting = (
  accountSettingType: AccountSettingType,
  id?: string | undefined
) => {
  return useMutationEnhancer<boolean | undefined, any>({
    mutationFn: async data => {
      const { method, data: _data } = data;

      const res = await APIManager.request({
        url: ENDPOINTS.restrictedClient(
          ':id/account-setting/:accountSettingType',
          {
            id,
            accountSettingType
          }
        ),
        method,
        showToast: true,
        body: _data
      });

      return res.success;
    },
    mutationKeys: [[QUERY_KEYS.client], [QUERY_KEYS.arAp]]
  });
};
