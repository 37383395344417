import { IBranch, IUser, IUserStaffPermission } from '@dto';
import {
  IAuthorizeParams,
  IAuthorizeResponse,
  IChangePasswordParams,
  ISignInParams,
  ISignInResponse
} from '@request-dto';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ENDPOINTS,
  IssueStatus,
  QUERY_KEYS,
  WebTable,
  tableRef,
  updateAuthorizationToken
} from '@constants';
import APIManager from '@services';
import { UIUtils } from 'utils';
import StorageEnhance, { STORAGE_KEYS } from 'utils/storage';

import {
  useCUDMutationEnhancer,
  useMutationEnhancer,
  useQueryEnhancer
} from '../core';
import { useUploadResource } from '../ui';

const mapUserPers = (userPers?: IUserStaffPermission) => {
  const data = (userPers?.permissionGroup ?? []).reduce<Record<string, string>>(
    (acc, cur) => {
      acc[cur.code] = cur.code;

      cur.permissionSubGroup?.forEach(i => {
        acc[i] = i;
      });

      return acc;
    },
    {}
  );

  return data;
};

export const useUser = () => {
  const { data: user, ...rest } = useQuery<IUser | null>({
    queryKey: [QUERY_KEYS.user],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.staff('current-profile')
      });

      if (res.data) {
        const localUser = StorageEnhance.get(STORAGE_KEYS.user);
        return { ...localUser, account: res.data };
      }

      return null;
    },
    enabled: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    initialData: StorageEnhance.get(STORAGE_KEYS.user),
    onError: () => {
      StorageEnhance.delete(STORAGE_KEYS.user);
    }
  });

  const { branch, staffPermission: userPers } = user || {};

  return {
    user,
    branch,
    staffPermission: userPers,
    userPers: mapUserPers(userPers),
    isAdmin: userPers?.isAdmin,
    isBranchAdmin: userPers?.isBranchAdmin,
    isSales: !!user?.account?.department?.isSales,
    ...rest
  };
};

export const useAuthorizeUser = () => {
  return useMutationEnhancer<IAuthorizeResponse | undefined, IAuthorizeParams>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.staff('authorize'),
        method: 'POST',
        body: data
      });

      if (!!res.data?.authorizationCode) {
        const { authorizationCode, staffInBranches } = res.data;

        return {
          authorizationCode: authorizationCode,
          branches: staffInBranches.map((i: any) => ({
            ...i.branch,
            isDefault: i.isDefault
          }))
        };
      }

      return undefined;
    }
  });
};

export const useStaffPermission = () => {
  const { data: user, ...rest } = useQuery<IUser | null>({
    queryKey: [QUERY_KEYS.user],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.staff('current-profile')
      });
      if (res.data) {
        const localUser = StorageEnhance.get(STORAGE_KEYS.user);
        return { ...localUser, account: res.data };
      }
      return null;
    },
    enabled: true,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    initialData: StorageEnhance.get(STORAGE_KEYS.user),
    onError: () => {
      StorageEnhance.delete(STORAGE_KEYS.user);
    }
  });

  const { account, branch } = user || {};

  const { data: permissions } = useQuery(
    ['permissions', account?.id, branch?.id],
    async () => {
      if (!account || !branch) return null;
      const resPermissions = await APIManager.request({
        url: ENDPOINTS.staff(`${account.id}/staff-role/permissions-detail`),
        body: {
          branchId: branch.id
        }
      });
      return resPermissions.data;
    },
    {
      enabled: !!account && !!branch,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    }
  );

  return {
    user,
    permissions,
    ...rest
  };
};

export const useEditMyProfile = () => {
  return useMutationEnhancer<any>({
    mutationFn: async requestData => {
      const res = await APIManager.request({
        url: ENDPOINTS.staff('current-profile'),
        method: 'PUT',
        showToast: true,
        body: requestData
      });
      return res.data;
    }
  });
};

interface IUseFetchPermissionForLogList {
  id: number;
  branchId: number;
}

export const useFetchPermissionForLogList = () => {
  return useMutationEnhancer<any, IUseFetchPermissionForLogList>({
    mutationFn: async mParams => {
      const { id, branchId } = mParams;

      const res = await APIManager.request({
        url: ENDPOINTS.staff(`${id}/staff-role/permissions-detail`),
        body: { branchId }
      });

      return res.data;
    }
  });
};

export const useFetchPermissionGroupList = () => {
  return useMutationEnhancer<any>({
    mutationFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.staff('permissions-group')
      });

      return res.data;
    }
  });
};

export const useSignIn = () => {
  const queryClient = useQueryClient();

  const { mutateAsync } = useFetchPermissionGroupList();

  return useMutationEnhancer<ISignInResponse, ISignInParams>({
    mutationFn: async data => {
      const { branch, ...rest } = data;
      const res = await APIManager.request({
        url: ENDPOINTS.staff('sign-in'),
        method: 'POST',
        body: {
          ...rest,
          branchId: branch.id
        }
      });

      return res.data;
    },
    onSuccess: async (data, variables) => {
      updateAuthorizationToken(data.token);

      const staffPermission = await mutateAsync();

      const userData = {
        ...data,
        branch: variables.branch,
        staffPermission
      };

      StorageEnhance.set(STORAGE_KEYS.user, userData);
      queryClient.setQueryData([QUERY_KEYS.user], userData);
      UIUtils.popup.dismiss();
    }
  });
};

export const useSwitchBranch = () => {
  const queryClient = useQueryClient();

  const { mutateAsync } = useFetchPermissionGroupList();

  const navigate = useNavigate();

  return useMutationEnhancer<ISignInResponse, IBranch>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.staff('switch-branch'),
        method: 'POST',
        body: {
          branchId: data.id
        }
      });

      return res.data;
    },
    onSuccess: async (data, variables) => {
      updateAuthorizationToken(data.token);

      const staffPermission = await mutateAsync();

      const userData = {
        ...data,
        branch: variables,
        staffPermission
      };
      StorageEnhance.set(STORAGE_KEYS.user, userData);
      queryClient.setQueryData([QUERY_KEYS.user], userData);
      UIUtils.popup.dismiss();
      navigate('/admin');
    }
  });
};

export const useSignOut = () => {
  const queryClient = useQueryClient();

  return useMutationEnhancer({
    mutationFn: () => {
      return APIManager.request({
        url: ENDPOINTS.staff('sign-out'),
        method: 'POST'
      });
    },
    onSuccess: () => {
      updateAuthorizationToken('');
      StorageEnhance.delete(STORAGE_KEYS.user);
      queryClient.setQueryData([QUERY_KEYS.user], null);
      UIUtils.popup.dismiss();
    }
  });
};

export const useChangePassword = () => {
  const signOut = useSignOut();

  return useMutationEnhancer<boolean | undefined, IChangePasswordParams>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.staff('change-password'),
        method: 'PUT',
        showToast: true,
        body: data
      });

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        UIUtils.popup.dismiss();
        signOut.mutate();
      }
    }
  });
};

export const useFetchUserBranchList = (id: number) => {
  const { data: branchList, ...rest } = useQueryEnhancer<IBranch[]>({
    queryKey: [QUERY_KEYS.staffBranchList, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.staff(':id/staff-branch', { id }),
        body: {
          staffId: id,
          includeFields: ['branch']
        }
      });

      return (res.data?.data ?? []).map((i: any) => ({
        ...i.branch,
        isDefault: i.isDefault
      }));
    },
    keepPreviousData: true,
    initialData: []
  });

  return {
    branchList,
    ...rest
  };
};

export const useFetchUserStaffBranch = () => {
  const { branch } = useUser();

  const { data: branchList, ...rest } = useQueryEnhancer<IBranch[]>({
    queryKey: [QUERY_KEYS.staffBranchList],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.staff('staff-branch'),
        body: {
          includeFields: ['branch', 'department']
        }
      });

      return (res.data?.data ?? []).map((i: any) => {
        return {
          ...i.branch,
          isDefault: i.isDefault,
          department: i.department
        };
      });
    },
    keepPreviousData: true,
    initialData: []
  });

  const currentBranch = useMemo(() => {
    return branchList?.find(i => i.id === branch?.id);
  }, [branch?.id, branchList]);

  return {
    branchList,
    currentBranch,
    ...rest
  };
};

export const useUpdateAvatar = (endPoint?: string) => {
  const { mutateAsync } = useUploadResource();

  return useMutationEnhancer<boolean | undefined, FormData>({
    mutationFn: async data => {
      const imageRes = await mutateAsync(data);

      if (imageRes) {
        const res = await APIManager.request({
          url: endPoint || ENDPOINTS.staff('avatar'),
          method: 'PUT',
          showToast: true,
          body: {
            avatar: imageRes.key
          }
        });

        return res.success;
      }

      return undefined;
    }
  });
};

export const useChooseBranchAsDefault = () => {
  return useMutationEnhancer<boolean | undefined, number>({
    mutationFn: async branchId => {
      const res = await APIManager.request({
        url: ENDPOINTS.staff('staff-branch/:branchId/default', { branchId }),
        method: 'PUT',
        showToast: true
      });

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        tableRef?.[WebTable.PROFILE_BRANCH]?.init?.();
      }
    }
  });
};

export const useFetchIssueResolve = (
  issueId?: string | null,
  activationCode?: string | null
) => {
  return useQueryEnhancer<{
    id: number;
    title: string;
    status: IssueStatus;
    expired: boolean;
  }>({
    queryKey: [QUERY_KEYS.issueResolve, issueId, activationCode],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.issueResolve(),
        body: {
          id: issueId,
          activationCode
        }
      });

      return res?.data;
    },
    enabled: !!issueId && !!activationCode
  });
};

export const useCUDIssueResolve = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.issueResolve()
  });
};

export const useCUDResendMail = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.issueResolve('/resend-mail')
  });
};

export const useCUDForgotPassword = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.staff('forget-password')
  });
};

export const useCUDResetPassword = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.staff('reset-password')
  });
};
