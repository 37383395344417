/* eslint-disable max-lines */
import { cloneDeep, get, isNumber } from 'lodash';
import React, { CSSProperties, createRef } from 'react';

import { DATE_FORMAT, DATE_FORMAT_SHORT, Status } from '@constants';
import Prototype from 'core/prototype';
import trans from 'translation';
import {
  KButton,
  KChip,
  KContainer,
  KImage,
  KLabel,
  KSwitch,
  KColors,
  MIcon,
  KSwitchProps,
  KChipProps,
  KListItem,
  KListItemBaseItemProps
} from 'uikit';

import UIUtils from './ui';

interface ITableChip extends Omit<KChipProps, 'label'> {
  label?: string;
}

class TableUtils {
  baseOptions = (css?: CSSProperties) => {
    return {
      setCellHeaderProps: () => {
        return {
          style: { whiteSpace: 'pre', ...css }
        };
      }
    };
  };

  pureBaseOptions = (css?: CSSProperties) => {
    return {
      ...this.baseOptions(css),
      sort: false,
      draggable: false,
      empty: true
    };
  };

  options = {
    base: this.baseOptions(),
    baseCenter: this.baseOptions({
      textAlign: 'center'
    }),

    baseSm: this.baseOptions({ minWidth: 80 }),
    baseNm: this.baseOptions({ minWidth: 100 }),
    baseMd: this.baseOptions({ minWidth: 120 }),
    baseXMd: this.baseOptions({ minWidth: 130 }),
    baseLg: this.baseOptions({ minWidth: 140 }),
    baseXLg: this.baseOptions({ minWidth: 160 }),
    base2XLg: this.baseOptions({ minWidth: 180 }),
    base3XLg: this.baseOptions({ minWidth: 200 }),

    action: this.pureBaseOptions({
      textAlign: 'center'
    }),
    actionWithEdit: (dataList: any[], onPress: (data: any) => void) => ({
      ...this.options.action,
      customBodyRenderLite: (index: number) => {
        const data = dataList?.[index];
        return (
          <KButton.Icon
            icon="Edit"
            size="sm"
            background={KColors.warning.normal}
            tintColor={KColors.white}
            br="x"
            onPress={() => onPress(data)}
            hasShadow
          />
        );
      }
    }),
    actionWithMenu: (
      dataList: any[],
      onPress: (data: any, ref?: any) => void
    ) => ({
      ...this.options.action,
      customBodyRenderLite: (index: number) => {
        const data = dataList?.[index];
        const ref = createRef<HTMLButtonElement>();
        return (
          <KContainer.View dp="flex" center>
            <KButton.Icon
              ref={ref}
              icon="MoreVertOutlined"
              size="sm"
              onPress={() => onPress(data, ref)}
            />
          </KContainer.View>
        );
      }
    }),
    actionWithMenuList: (
      dataList: any[],
      menuData: (data: any, dismiss?: () => void) => KListItemBaseItemProps[],
      autoDismiss: boolean = true,
      displayFn?: (item: any) => boolean
    ) => ({
      ...this.options.action,
      customBodyRenderLite: (index: number) => {
        const ref = createRef<HTMLButtonElement>();

        const data = dataList?.[index];

        const isHidden =
          displayFn?.(data) || menuData(data).length === 0 || false;

        if (isHidden) return null;

        return (
          <KContainer.View dp="flex" center>
            <KButton.Icon
              ref={ref}
              icon="MoreVertOutlined"
              size="sm"
              // avoidParentPress
              onPress={() => {
                UIUtils.popper.open({
                  anchorEl: ref.current,
                  touchOutsideToDismiss: true,
                  placement: 'right-start',
                  content: dismiss => (
                    <KListItem.Base
                      alignItems
                      data={menuData(data, dismiss).map(i => ({
                        ...i,
                        onPress: () => {
                          (i.onPress as any)?.();
                          autoDismiss && dismiss();
                        }
                      }))}
                    />
                  )
                });
              }}
            />
          </KContainer.View>
        );
      }
    }),
    actionWithView: (dataList: any[], onPress: (data: any) => void) => ({
      ...this.options.action,
      customBodyRenderLite: (index: number) => {
        const data = dataList?.[index];
        return (
          <KButton.Icon
            icon="Visibility"
            size="sm"
            background={KColors.warning.normal}
            tintColor={KColors.white}
            br="x"
            onPress={() => onPress(data)}
            hasShadow
          />
        );
      }
    }),

    icon: (
      mappedData: {
        key: any;
        icon: MIcon;
        color?: string;
      }[],
      center?: boolean
    ) => {
      return {
        ...this.options.baseCenter,
        customBodyRender: (v?: any) => {
          const data = mappedData.find(i => i.key === v);
          if (data) {
            if (center) {
              return (
                <KContainer.View dp="flex" center>
                  <KImage.MuiIcon icon={data.icon} color={data.color} />
                </KContainer.View>
              );
            }
            return <KImage.MuiIcon icon={data.icon} color={data.color} />;
          }
          return undefined;
        }
      };
    },
    iconCheck: (data?: {
      revert?: boolean;
      center?: boolean;
      revertKey?: boolean;
    }) => {
      const { revert, revertKey, center = true } = data || {};
      return this.options.icon(
        [
          {
            key: revertKey ? false : true,
            icon: 'CheckRounded',
            color: revert ? KColors.secondary.normal : KColors.primary.normal
          }
        ],
        center
      );
    },
    iconCheckCircle: (data?: {
      revert?: boolean;
      center?: boolean;
      revertKey?: boolean;
    }) => {
      const { revert, revertKey, center = false } = data || {};
      return this.options.icon(
        [
          {
            key: revertKey ? false : true,
            icon: 'CheckCircleOutlineOutlined',
            color: revert ? KColors.secondary.normal : KColors.primary.normal
          }
        ],
        center
      );
    },

    mappedOption: () => ({
      customBodyRender: (v?: string) =>
        v ? trans(`option.${v.toLowerCase()}`).toUpperCase() : ''
    }),

    date: (f: string = DATE_FORMAT) => ({
      ...this.baseOptions({ minWidth: f === DATE_FORMAT ? 150 : 100 }),
      customBodyRender: (v?: string) => Prototype.date.toLocal(v)?.format(f)
    }),
    dateWithOffset: (
      data: any[],
      tzKey?: string,
      f: string = DATE_FORMAT_SHORT
    ) => ({
      ...this.options.date(f),
      customBodyRender: (v?: string, rowData?: any) => {
        const tz = tzKey ? data?.[rowData?.rowIndex]?.[tzKey] : undefined;
        const code = tz?.code;
        return v
          ? Prototype.date.toTz(v, code)?.format(f) +
              (tz ? ` (${tz?.offset ?? code ?? 'GMT+00:00'})` : '')
          : '';
      }
    }),
    dateWithOffsetCode: (
      data: any[],
      tzKey?: string,
      f: string = DATE_FORMAT_SHORT
    ) => ({
      ...this.options.date(f),
      customBodyRender: (v?: string, rowData?: any) => {
        const tz = tzKey ? data?.[rowData?.rowIndex]?.[tzKey] : undefined;
        const code = tz;
        return v
          ? Prototype.date.toTz(v, code)?.format(f) +
              (tz ? ` (${tz?.offset ?? code ?? 'GMT+00:00'})` : '')
          : '';
      }
    }),

    number: (defaultValue: any = '') => ({
      ...this.baseOptions(),
      customBodyRender: (v?: number) =>
        isNumber(v) ? Prototype.number.formatNumber(v) : defaultValue
    }),
    numberWoPad: (defaultValue: any = '') => ({
      ...this.baseOptions(),
      customBodyRender: (v?: number) =>
        isNumber(v)
          ? Prototype.number.formatNumber(v, { pad: false })
          : defaultValue
    }),

    numberWithCurrency: (
      data: any[],
      currencyKey: string = 'currency',
      defaultValue: any = ''
    ) => ({
      ...this.baseOptions(),
      customBodyRender: (v?: number, rowData?: any) => {
        const currency = currencyKey
          ? data?.[rowData?.rowIndex]?.[currencyKey]
          : undefined;
        const code = currency?.code;

        return isNumber(v)
          ? [code, Prototype.number.formatNumber(v)].filter(i => i).join(' - ')
          : defaultValue;
      }
    }),

    textWithStatus: (data: any[], optionStatus: any, keyStatus: string) => ({
      ...this.options.base2XLg,
      customBodyRender: (v?: number, rowData?: any) => {
        const item = data[rowData.rowIndex];
        const _status = item?.[keyStatus];

        const _enDash = !!(v && _status) ? ' - ' : '';

        const options = optionStatus?.[_status];
        return (
          <>
            <KLabel.Text>{v}</KLabel.Text> {_enDash}
            <KLabel.Text
              {...options}
              typo="TextXNmMedium"
              background={KColors.transparent}
              textTransform="uppercase"
            >
              {options?.label}
            </KLabel.Text>
          </>
        );
      }
    }),

    withLink: (data: any[]) => {
      return {
        customBodyRender: (v?: string, rowData?: any) => {
          const item = data?.[rowData.rowIndex];

          if (item?.id) {
            return (
              <KLabel.Text isLink to={`${item?.id}`}>
                {v}
              </KLabel.Text>
            );
          }

          return v;
        }
      };
    },
    withEllipsis: {
      ...this.baseOptions({ minWidth: 160 }),
      customBodyRender: (v?: string) =>
        v ? <KLabel.Text numberOfLines={4}>{v}</KLabel.Text> : undefined
    },
    withNA: {
      ...this.baseOptions(),
      customBodyRender: (v?: string) => v || 'N/A'
    },
    // eslint-disable-next-line no-unused-vars
    withTooltip: (normalizeData: (data: any) => string) => {
      return {
        ...this.options.base3XLg,
        customBodyRender: (v?: any) => {
          const data = v ? normalizeData(v) : undefined;
          if (data) {
            return (
              <KLabel.Text numberOfLines={1} withTooltip>
                {data}
              </KLabel.Text>
            );
          }
          return undefined;
        }
      };
    },
    withMoreIcon: (data?: {
      onPress?: (data: any[]) => void;
      renderItem?: (i: any, index: number, rowIndex: number) => React.ReactNode;
      maxLength?: number;
      onNormalizeData?: (rowData: any) => any;
      isHorizontal?: boolean;
      key?: string;
      kind?: 'primary' | 'warning';
    }) => {
      const {
        onPress,
        maxLength = 2,
        renderItem,
        onNormalizeData,
        isHorizontal = true,
        key,
        kind = 'primary'
      } = data || {};

      const brOrBg = KColors[kind].normal;

      return {
        ...(maxLength === 1
          ? this.options.base2XLg
          : this.baseOptions({ minWidth: 250 })),
        customBodyRender: (value: any[], rowData: any) => {
          const v = onNormalizeData ? onNormalizeData(rowData) : value;

          if (v && Array.isArray(v)) {
            const { rowIndex, tableData, columnData } = rowData;
            const id = tableData?.[rowIndex]?.id ?? 'new';
            const headerName = columnData?.label ?? '';
            const name = columnData?.name ?? '';

            const _onPress = (a: any[]) => {
              if (onPress) {
                return onPress(a);
              }

              UIUtils.popup.open({
                title: headerName,
                maxWidth: 'xs',
                touchOutsideToDismiss: true,
                content: () => (
                  <>
                    {(a ?? []).map((i, idx) => {
                      const marginT = idx === 0 ? 0 : '0.75rem';

                      return (
                        <KContainer.View
                          key={`popup-${id}-${name}-${idx}`}
                          dp="flex"
                          center
                          paddingV="0.25rem"
                          paddingH="0.75rem"
                          br="x"
                          brW={1}
                          brC={brOrBg}
                          marginT={marginT}
                        >
                          <KLabel.Text typo="TextMdNormal">
                            {key ? get(i, key, '') : i}
                          </KLabel.Text>
                        </KContainer.View>
                      );
                    })}
                  </>
                )
              });
            };

            const arr = cloneDeep(v);
            const displayArr = arr.slice(0, maxLength);

            if (!isHorizontal) {
              return (
                <KContainer.View>
                  {displayArr.map((i, idx) => {
                    const marginT = idx === 0 ? 0 : '0.25rem';

                    return idx === displayArr.length - 1 ? (
                      <KContainer.View
                        row
                        alignItems
                        marginT={marginT}
                        key={idx}
                      >
                        {renderItem?.(i, idx, rowIndex)}

                        {arr.length > maxLength && (
                          <KButton.Icon
                            icon="MoreHoriz"
                            tintColor={KColors.white}
                            background={brOrBg}
                            br="x"
                            size="sm"
                            onPress={() => _onPress(arr)}
                            marginL="0.25rem"
                          />
                        )}
                      </KContainer.View>
                    ) : (
                      <KContainer.View marginT={marginT} key={idx}>
                        {renderItem?.(i, idx, rowData.rowIndex)}
                      </KContainer.View>
                    );
                  })}
                </KContainer.View>
              );
            }

            return (
              <KContainer.View row alignItems flexW="wrap" gap="0.25rem">
                {displayArr.map((i, idx) => {
                  if (renderItem) {
                    return renderItem(i, idx, rowIndex);
                  }

                  return (
                    <KContainer.View
                      key={`${id}-${name}-${idx}`}
                      minW={83}
                      height={22}
                      dp="flex"
                      center
                      background={KColors.palette[kind].w50}
                      brC={brOrBg}
                      br="x"
                      brW={1}
                      paddingH="0.25rem"
                    >
                      <KLabel.Text color={brOrBg}>
                        {key ? get(i, key, '') : i}
                      </KLabel.Text>
                    </KContainer.View>
                  );
                })}

                {arr.length > maxLength && (
                  <KButton.Icon
                    icon="MoreHoriz"
                    tintColor={KColors.white}
                    background={brOrBg}
                    br="x"
                    size="sm"
                    onPress={() => _onPress(arr)}
                  />
                )}
              </KContainer.View>
            );
          }
          return undefined;
        }
      };
    },
    withBreakLines: (data?: any[]) => {
      return {
        customBodyRender: (v: string, rowData: any) => {
          const item = data?.[rowData.rowIndex];
          if (item && v?.includes('\n')) {
            const arr = v.split('\n');
            const displayArr = arr.slice(0, 5);

            const displays = displayArr.map((i, idx) =>
              i ? (
                <KLabel.Paragraph key={`${item.id}-${i}-${idx}`}>
                  {i}
                  {idx === 3 && arr.length > displayArr.length && (
                    <KLabel.Text key={`${item.id}-more`}>...</KLabel.Text>
                  )}
                </KLabel.Paragraph>
              ) : (
                <br key={`${item.id}-br-${idx}`} />
              )
            );

            return displays;
          }

          return v ? <KLabel.Text numberOfLines={4}>{v}</KLabel.Text> : '';
        }
      };
    },

    chip: (data?: {
      css?: CSSProperties;
      width?: number;
      label?: (v?: string) => string;
      kind?: string;
      props?: ITableChip;
    }) => ({
      ...this.baseOptions(data?.css),
      customBodyRender: (v?: string) => {
        if (v) {
          const { kind } = data || {};

          return (
            <KChip
              background={
                kind === 'info' ? KColors.palette.blue.w50 : '#DEFFFD'
              }
              brC={
                kind === 'info'
                  ? KColors.palette.blue.w600
                  : KColors.primary.normal
              }
              color={
                kind === 'info'
                  ? KColors.palette.blue.w600
                  : KColors.primary.normal
              }
              label={data?.label?.(v) || v}
              width={data?.width}
              textTransform={'uppercase'}
              style={{ whiteSpace: 'nowrap' }}
              {...data?.props}
            />
          );
        }

        return undefined;
      }
    }),
    chips: (data: {
      css?: CSSProperties;
      uppercase?: boolean;
      width?: number;
      mapLabelData: {
        [key: string]: ITableChip;
      };
      notFoundKey?: string;
    }) => ({
      ...this.baseOptions(data?.css),
      customBodyRender: (v?: any) => {
        let item = v;
        if (v) {
          if (typeof v === 'string') {
            item = {
              name: v,
              ...(data?.mapLabelData?.[v] ??
                data?.mapLabelData?.[v.toLowerCase()])
            };
          } else if (typeof v === 'boolean') {
            item = {
              name: v,
              ...(data?.mapLabelData?.[`${v}`] ??
                data?.mapLabelData?.[`${v}`.toLowerCase()])
            };
          }
        } else if (data.notFoundKey) {
          item = {
            name: data.notFoundKey,
            ...(data?.mapLabelData?.[`${data.notFoundKey}`] ??
              data?.mapLabelData?.[`${data.notFoundKey}`.toLowerCase()])
          };
        }

        if (item) {
          const { name, label, ...rest } = item;
          return (
            <KChip
              background={'#DEFFFD'}
              brC={KColors.primary.normal}
              label={label ?? name ?? ''}
              textTransform={data?.uppercase ? 'uppercase' : 'capitalize'}
              color={KColors.primary.normal}
              width={data?.width}
              {...rest}
            />
          );
        }

        return undefined;
      }
    }),
    default: (label?: string) => {
      return {
        ...this.options.action,
        ...this.options.chips({
          uppercase: true,
          width: 90,
          mapLabelData: {
            ['true']: {
              color: KColors.blue.dark,
              background: KColors.opacity.blue[47],
              label: trans(label || 'default')
            }
          }
        })
      };
    },

    switch: (isTrue = Status.Active, props?: KSwitchProps) => {
      return {
        ...this.pureBaseOptions({
          textAlign: 'center'
        }),
        customBodyRender: (v?: string) => {
          return (
            <KContainer.View dp="flex" center>
              <KSwitch id="status" checked={v === isTrue} {...props} />
            </KContainer.View>
          );
        }
      };
    },
    status: () => {
      return this.options.switch(Status.Active, {
        disabled: true
      });
    },
    checkbox: {
      ...this.baseOptions(),
      sort: false,
      customBodyRender: (v?: boolean) => (
        <KContainer.View center>
          <KImage.MuiIcon
            icon={v ? 'CheckBoxOutlined' : 'CheckBoxOutlineBlankOutlined'}
            color={v ? KColors.primary.normal : KColors.black}
            style={{ verticalAlign: 'middle' }}
          />
        </KContainer.View>
      )
    },
    chipList: {
      ...this.baseOptions(),
      customBodyRender: (v?: any[]) => (
        <KContainer.RenderWhen>
          <KContainer.RenderWhen.If isTrue={(v?.length ?? 0) > 0}>
            <KContainer.View row alignItems flexW="wrap">
              {v?.map(i => (
                <KChip
                  key={i}
                  background={'#FFF1E0'}
                  margin={'0.25rem'}
                  marginL={0}
                  label={i.code ?? ''}
                />
              ))}
            </KContainer.View>
          </KContainer.RenderWhen.If>
        </KContainer.RenderWhen>
      )
    },
    chipListLg: {
      ...this.baseOptions({ minWidth: 120 }),
      customBodyRender: (v?: any[]) => (
        <KContainer.RenderWhen>
          <KContainer.RenderWhen.If isTrue={(v?.length ?? 0) > 0}>
            <KContainer.View row alignItems flexW="wrap">
              {v?.map(i => (
                <KChip
                  key={i}
                  background={'#FFF1E0'}
                  margin={'0.25rem'}
                  marginL={0}
                  label={i.code ?? ''}
                />
              ))}
            </KContainer.View>
          </KContainer.RenderWhen.If>
        </KContainer.RenderWhen>
      )
    },

    address: {
      ...this.baseOptions({ minWidth: 180 }),
      customBodyRender: (v: any) => {
        return [v?.client?.name, v?.name].filter(i => i).join(' - ');
      }
    },

    toggle: (flag = true): any => ({
      display: !flag ? 'excluded' : true,
      viewColumns: flag
    }),
    boolean: (data: [any, any] = ['YES', 'NO']) => ({
      customBodyRender: (v: any) => (v ? data[0] : data[1])
    }),
    pinColumn: {
      setCellProps: () => ({
        style: {
          whiteSpace: 'nowrap',
          position: 'sticky',
          right: '0',
          background: 'white',
          zIndex: 100
        }
      }),
      setCellHeaderProps: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            right: '0',
            background: 'white',
            zIndex: 100
          }
        } as any;
      }
    },

    contentMore: (
      onPress: (o: any) => void,
      data: any[],
      lengthStr?: {
        from: number;
        to: number;
      }
    ) => {
      const { from = 0, to = 30 } = lengthStr || {};
      return {
        ...this.baseOptions({ minWidth: 160 }),
        customBodyRender: (v?: string, rowData?: any) => {
          const item = data[rowData.rowIndex];
          return v ? (
            <KLabel.Text
              onPress={() => {
                onPress(item);
              }}
            >
              {v?.length <= (to || 30) ? v : `${v?.slice(from, to)}...`}
            </KLabel.Text>
          ) : (
            ''
          );
        }
      };
    }
  };
}

export default new TableUtils();
